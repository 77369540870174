import React, { useCallback, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import openModalIcon from "../../assets/openModal.svg";
import UserSatisfaction from "./UserSatisfaction";
import "./ResponseQuality.css";
import ToolTip from "../../components/ToolTip";
import axios from "axios";
import debounce from "../../utils/Debounce";

const RQDonutChart = ({ onOpenUserSatisfaction, selectedFilters, selectedMonth, selectedYear }) => {

  const [userSatisfaction, setUserSatisfaction] = useState({});
  const [chartData, setChartData] = useState([]);

  const getUserSatisfactionData = useCallback(debounce(async (filters) => {
    try {
      const url = `https://genai-cms-api.nawatech.co/api/sma/response-quality/total-user-satisfaction`;
      const response = await axios.post(url, filters);
      if (response.status == 200) {
        const result = response.data;
        setUserSatisfaction(result.data.user_satisfaction);
        if (result.data.user_satisfaction.thumbs_up.total || result.data.user_satisfaction.thumbs_down.total) {
          setChartData([
            result.data.user_satisfaction.thumbs_up.total,
            result.data.user_satisfaction.thumbs_down.total
          ]);
        } else {
          setChartData([]);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, 1000), [])


  useEffect(() => {
    getUserSatisfactionData(selectedFilters);
  }, [selectedFilters])

  const plugin = {
    id: 'emptyDoughnut',
    afterDraw(chart, args, options) {
      const { datasets } = chart.data;
      const { color, width, radiusDecrease } = options;
      let hasData = false;

      for (let i = 0; i < datasets.length; i += 1) {
        const dataset = datasets[i];
        hasData |= dataset.data.length > 0;
      }

      if (!hasData) {
        const { chartArea: { left, top, right, bottom }, ctx } = chart;
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const r = Math.min(right - left, bottom - top) / 2;

        ctx.beginPath();
        ctx.lineWidth = width || 2;
        ctx.strokeStyle = '#e6e6e8';
        ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
        ctx.stroke();
      }
    }
  };

  const data = {
    labels: ["Thumbs Up", "Thumbs Down"],
    datasets: [
      {
        data: chartData,
        backgroundColor: ["#33D616", "#F77B65"],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      emptyDoughnut: {
        color: 'rgba(255, 128, 0, 0.5)',
        width: 2,
        radiusDecrease: 20
      }
    },
  };

  const legendData = [
    {
      label: "Thumbs Up",
      value: userSatisfaction.thumbs_up ? userSatisfaction.thumbs_up.total : 0,
      percentage: userSatisfaction.thumbs_down ? userSatisfaction.thumbs_up.percentage : 0,
      color: "#33D616",
    },
    {
      label: "Thumbs Down",
      value: userSatisfaction.thumbs_up ? userSatisfaction.thumbs_down.total : 0,
      percentage: userSatisfaction.thumbs_down ? userSatisfaction.thumbs_down.percentage : 0,
      color: "#F77B65",
    },
  ];

  return (
    <div className="rq-section w-400">
      <h1 className="section-title group-flex-2 align-center">
        Total User Satisfaction
        <ToolTip text={"A comparison of total user satisfaction based on the total number of thumbs-up and thumbs-down reactions"} />
        <img
          src={openModalIcon}
          alt="redirect icon"
          className="redirect-icon cursor-pointer"
          onClick={onOpenUserSatisfaction}
        />
      </h1>
      <div className="rq-chart-and-legend">
        <div style={{ position: "relative", height: "200px", width: "200px" }}>
          <Doughnut className="rq-donut"
            data={data}
            options={chartOptions}
            plugins={[plugin]}
          />
        </div>
        <div className="rq-legend-container">
          {legendData.map((item, index) => (
            <div key={index} className="rq-legend-item">
              <div className="rq-legend-info">
                <div
                  className="rq-legend-color-box"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span style={{ marginRight: "10px" }}>{item.label}</span>
              </div>
              <div className="rq-legend-value">
                <span className="rq-value">{item.value}</span>
                <div className="rq-percentage-bar">
                  <div
                    className="rq-percentage-fill"
                    style={{
                      width: item.percentage + "%",
                      backgroundColor: item.color,
                    }}
                  ></div>
                </div>
                <span className="rq-percentage-text">{item.percentage}%</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RQDonutChart;
