import loadingIcon from "../assets/loadingIcon.svg";

const LoadingComponent = ({message}) => {
    return (
        <div className="loading-container">
            <img
                src={loadingIcon}
                alt="Loading..."
                className="loading-icon"
            />
            <p className="loading-message">{message}</p>
        </div>
    )
}

export default LoadingComponent;