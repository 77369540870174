import { useContext } from "react"
import { PromptPropertiesContext } from "../contexts/PromptPropertiesProvider"


export default function usePromptMetrics() {
    const {
        activeTab,
        setActiveTab,
        currentGeneralPage,
        setCurrentGeneralPage,
        selectedSpesificFilters,
        setSelectedSpesificFilters,
        currentSpesificPage,
        setCurrentSpesificPage
    } = useContext(PromptPropertiesContext);
    
    return {
        activeTab,
        setActiveTab,
        currentGeneralPage,
        setCurrentGeneralPage,
        selectedSpesificFilters,
        setSelectedSpesificFilters,
        currentSpesificPage,
        setCurrentSpesificPage
    }
}