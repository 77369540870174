import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Tooltip,
  BarController,
} from 'chart.js';
import { useEffect, useState } from 'react';

import { Chart } from 'react-chartjs-2';
import ToolTip from '../../components/ToolTip';

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, PointElement, Tooltip);

const TotalFunctionChart = ({selectedFilters, selectedMonth, selectedYear}) => {
  const [listColors, setListColors] = useState([]);
  const sampleData = [32, 27, 14, 24, 35, 30, 20].sort((a, b) => b - a);
  const sampleLabels = [
    'get_metrics_summary',
    'get_metrics_contribute',
    'get_top_or_ranking',
    'get_distinct_location',
    'create_alert_measurement',
    'thumbs_up_down',
    'get_chatbot_capability',
  ];

  const generateColor = (ratio) => {
    let r = 209; // Mengurangi nilai merah secara bertahap
    let g = 90;       // Meningkatkan nilai hijau secara bertahap
    let b = 90;
    if (ratio < 0.5) {
      // Dari merah ke kuning
      r = 209;
      g = Math.round(2 * ratio * 90 + 90); // Mengatur hijau dari 0 ke 255
    } else {
      // Dari kuning ke hijau
      r = Math.round(209 - (119 * (ratio - 0.5) * 2)); // Mengurangi merah dari 255 ke 0
      g = 209;
    }
    return `rgb(${r}, ${g}, ${b})`;
  }

  const generateListColors = (dataLength) => {
    const colors = [];
    for (let i = 0; i < dataLength; i++) {
      const ratio = i / (dataLength - 1);
      colors.push(generateColor(ratio));
    }
    setListColors(colors);
  }

  useEffect(() => {
    generateListColors(sampleData.length);
  }, []);

  const data = {
    labels: sampleLabels,
    datasets: [
      {
        type: 'bar',
        backgroundColor: listColors,
        data: sampleData,
        borderColor: 'white',
        borderWidth: 2,
        maxBarThickness: 70
      },
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className='ue-section'>
      <h3 className="section-title">
        Total Function by Query
        <ToolTip text={"Most frequently queried Function by users, sorted from highest to lowest"} />
      </h3>
      <div>
        <Chart type='bar' data={data} options={chartOptions} style={{ height: '300px' }} />
      </div>
    </div>
  )
}

export default TotalFunctionChart;