import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    isSidePanelOpen: true,
    openDropdown: null,
}

const sidePanelSlice = createSlice({
    name: "sidePanelSlice",
    initialState,
    reducers: {
        handleSwitchSidePanel: (state, action) => {
            state.isSidePanelOpen = !state.isSidePanelOpen
            state.openDropdown = null;
        },
        handleOpenDropdown: (state, action) => {
            state.openDropdown = action.payload
        }
    }
})

export const { handleSwitchSidePanel, handleOpenDropdown } = sidePanelSlice.actions
export default sidePanelSlice.reducer