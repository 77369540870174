import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Tooltip,
  BarController,
} from 'chart.js';
import { useState } from 'react';

import { Chart } from 'react-chartjs-2';
import ToolTip from '../../components/ToolTip';

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, PointElement, Tooltip);

const TotalInteractionByRoleChart = ({selectedFilters, selectedMonth, selectedYear}) => {
  const [selectedMetric, setSelectedMetric] = useState('all');

  const handleMetricChange = (e) => setSelectedMetric(e.target.value);

  const sampleData = {
    all: [32, 27, 14, 24, 35, 30, 32, 27, 14, 24, 35, 30, 20, 15, 10, 5, 28],
    psm: [32, 27, 14, 24, 35, 30, 20, 15],
    region: [32, 27, 14],
    estate: [32, 27, 14, 24, 35, 30],
  };
  const sampleLabels = {
    all: [
      'PSM 1',
      'PSM 2',
      'PSM 3',
      'PSM 4',
      'PSM 5',
      'PSM 6',
      'PSM 6A',
      'PSM 7',
      'Jakarta',
      'Bandung',
      'Surabaya',
      'Estate 1',
      'Estate 2',
      'Estate 3',
      'Estate 4',
      'Estate 5',
      'Estate 6',
    ],
    psm: [
      'PSM 1',
      'PSM 2',
      'PSM 3',
      'PSM 4',
      'PSM 5',
      'PSM 6',
      'PSM 6A',
      'PSM 7',
    ],
    region: [
      'Jakarta',
      'Bandung',
      'Surabaya',
    ],
    estate: [
      'Estate 1',
      'Estate 2',
      'Estate 3',
      'Estate 4',
      'Estate 5',
      'Estate 6',
    ],
  };

  const data = {
    labels: sampleLabels[selectedMetric],
    datasets: [
      {
        type: 'bar',
        backgroundColor: 'rgb(113, 174, 235)',
        data: sampleData[selectedMetric],
        borderColor: 'white',
        borderWidth: 2,
        maxBarThickness: 70
      },
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className='ue-section'>
      <div className='ue-filter-container'>
        <h3 className="section-title">
          Total Interaction by Access Role
          <ToolTip text={"The total number of interactions categorized by access role"} />
        </h3>
        <select onChange={handleMetricChange}
          value={selectedMetric}
          className='ue-select-form'>
          <option value="all">ALL ACCESS &#40; Include MDO 1 and MDO 2 &#41;</option>
          <option value="psm">MVD Investor - Downstream</option>
          <option value="region">MVD Investor - Mill/Estate</option>
          <option value="estate">ICON360</option>
        </select>
      </div>
      <div>
        <Chart type='bar' data={data} options={chartOptions} style={{ height: '300px' }} />
      </div>
    </div>
  )
}

export default TotalInteractionByRoleChart;