import React from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import downloadIcon from "../../assets/downloadIcon.svg";
import { Link } from "react-router-dom";

const DownloadModal = ({ filters, isOpen, onClose }) => {
  return (
    <div className={`download-modal ${isOpen ? "open" : ""}`}>
      <div className="download-modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={cancelIcon} alt="cancel icon" onClick={onClose} />
        </button>
        <h2 className="download-title">Download</h2>
        <hr className="download-separator" />
        <div className="file-download-section">
          <div className="file-download-item">
            <span className="file-name">General Properties.xlsx</span>
            <Link
              className="download-icon-button"
              download
              to="https://genai-cms-api.nawatech.co/api/sma/prompt_properties/get-general/?page=1&size=9999&is_export=true&order_by=updated_at&sort=desc"
              target="_blank"
            >
              <img src={downloadIcon} alt="download icon" />
            </Link>
          </div>
          <div className="file-download-item">
            <span className="file-name">Specific Properties.xlsx</span>
            <Link
              className="download-icon-button"
              download
              to={`https://genai-cms-api.nawatech.co/api/sma/prompt_properties/get-spesific/?page=1&size=9999&is_export=true&order_by=updated_at&sort=desc${filters.value
                  ? `&function_id=${filters.value}`
                  : ""
                }`}
              target="_blank"
            >
              <img src={downloadIcon} alt="download icon" />
            </Link>
          </div>
        </div>
        <div className="download-all-section">
          <button
            className="download-all-button"
            onClick={() => {
              window.open(
                "https://genai-cms-api.nawatech.co/api/sma/prompt_properties/get-general/?page=1&size=9999&is_export=true&order_by=updated_at&sort=desc",
                "_blank",
                ""
              );
              window.open(
                `https://genai-cms-api.nawatech.co/api/sma/prompt_properties/get-spesific/?page=1&size=9999&is_export=true&order_by=updated_at&sort=desc${filters.value
                  ? `&function_id=${filters.value}`
                  : ""
                }`,
                "_blank",
                ""
              );
            }}
          >
            Download All
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
