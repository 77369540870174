import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import debounce from "../../utils/Debounce";
import LoadingComponent from "../../components/Loading";

const TCCAnalyticTable = ({ selectedFilters, selectedMonth, selectedYear }) => {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getFunctionData = useCallback(debounce(async (filters) => {
    setIsLoading(true)
    try {
      const url = `https://genai-cms-api.nawatech.co/api/sma/token-consumption/table-function-token-consumption`;
      const response = await axios.post(url, filters);
      if (response.status == 200) {
        const result = response.data;
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false)
  }, 1000), [])

  useEffect(() => {
    getFunctionData(selectedFilters);
  }, [selectedFilters])

  return (
    <div className="tcc-analytic-table">
      <table>
        <thead>
          <tr>
            <th>Function</th>
            <th>Avg. Token Consumption</th>
            <th>Avg. Token Cost Consumption</th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? <tr>
              <td colSpan={4} className="text-center">
                <LoadingComponent message={"Calculating data..."} />
              </td>
            </tr>
            : data.map((item, index) => (
              <tr key={index}>
                <td>{item.function}</td>
                <td>{item.avg_token_consumption}</td>
                <td>{item.avg_token_cost_consumption}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default TCCAnalyticTable;