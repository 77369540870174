import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import './ResponseQuality.css';
import axios from 'axios';
import debounce from '../../utils/Debounce';
import LoadingChart from '../../components/LoadingChart';
import Select from 'react-select';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const RQLineChart = ({ chartWidth, selectedFilters, selectedMonth, selectedYear }) => {
  const [selectedMetric, setSelectedMetric] = useState({ value: 'success_rate', label: 'Success Rate' });
  const [selectedTime, setSelectedTime] = useState({ value: 'day', label: 'Daily' });
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSelectDayDisabled, setIsSelectDayDisabled] = useState(false)

  const listMetrics = [
    { value: 'success_rate', label: 'Success Rate' },
    { value: 'fallback_rate', label: 'Fallback Total' },
    { value: 'satisfaction_score', label: 'Satisfaction Score' },
    { value: 'user_rating', label: 'User Rating' },
    { value: 'response_time', label: 'Response Time' },
  ]

  const listTime = [
    { value: 'day', label: 'Daily' },
    { value: 'month', label: 'Monthly' },
    { value: 'year', label: 'Yearly' },
  ]

  const handleMetricChange = (selectedOptions) => {
    setSelectedMetric(selectedOptions);
  };

  const handleTimeChange = (selectedOptions) => {
    setSelectedTime(selectedOptions)
  };

  const getData = useCallback(debounce(async (filters, metric, time) => {
    setIsLoading(true)
    try {
      const url = `https://genai-cms-api.nawatech.co/api/sma/response-quality/line-chart`;
      const response = await axios.post(url, {
        ...filters,
        line_type: metric.value,
        period_type: time.value
      });
      if (response.status == 200) {
        const result = await response.data;
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false)
  }, 1000), [])

  useEffect(() => {
    getData(selectedFilters, selectedMetric, selectedTime);
  }, [selectedFilters, selectedMetric, selectedTime])

  useEffect(() => {
    if (selectedMonth.length > 1 || selectedYear.length > 1) {
      setIsSelectDayDisabled(true)
      setSelectedTime({ value: 'month', label: 'Monthly' })
    } else {
      setIsSelectDayDisabled(false)
    }
  }, [selectedMonth, selectedYear])

  const chartData = {
    labels: data.map((x) => x.date),
    datasets: [
      {
        data: data.map((x) => x.total),
        borderColor: 'white',
        backgroundColor: '#71AEEB',
        borderWidth: 2,
        maxBarThickness: 70,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      y: {
        suggestedMin: 0,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        suggestedMax: 5,
      }
    },
  };

  return (
    <div className='rq-section grow'>
      <div className='group-flex-8 justify-between mb-16'>
        <Select
          id="metricType"
          name="metricType"
          options={listMetrics}
          value={selectedMetric}
          onChange={handleMetricChange}
          placeholder={"-- Select Metric --"}
          classNamePrefix="select"
        />
        <Select
          id="periodType"
          name="periodType"
          options={listTime}
          value={selectedTime}
          onChange={handleTimeChange}
          placeholder={"-- Select Period --"}
          classNamePrefix="select"
          isOptionDisabled={(option) => option.value === 'day' && isSelectDayDisabled}
        />
      </div>
      <div className='rq-chart-container' style={{ position: "relative", width: chartWidth ?? '100%', height: '300px' }}>
        {isLoading &&
          <LoadingChart />
        }
        <Bar
          data={chartData}
          options={chartOptions}
        />
      </div>
    </div>
  );
};

export default RQLineChart;
