import React from 'react';

const ThumbsUp = () => {
    return (
        <>
            <span>Thumbs Up </span>&#128077;
        </>
    );
};

export default ThumbsUp