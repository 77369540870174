import React, { useCallback, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "./TokenCostConsumption.css";
import ToolTip from "../../components/ToolTip";
import axios from "axios";
import debounce from "../../utils/Debounce";
import { numberWithCommas } from "../../utils/FunctionHelpers";

const TCCDonutChart = ({ selectedFilters, selectedMonth, selectedYear }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getData = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    try {
      const URL = "https://genai-cms-api.nawatech.co/api/sma/token-consumption/input-output-token";
      const response = await axios.post(URL, filters);
      if (response.status == 200) {
        const result = response.data;
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  }, 500), []);

  const dataChart = {
    labels: ["Total Input Token", "Total Output Token"],
    datasets: [
      {
        data: [
          data.input_token ? data.input_token.value : 15,
          data.output_token ? data.output_token.value : 15,
        ],
        backgroundColor: ["#16BFD6", "#F765A3"],
      },
    ],
  };

  useEffect(() => {
    getData(selectedFilters);
  }, [selectedFilters]);


  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const legendData = [
    {
      label: "Total Input Token",
      value: data.input_token ? data.input_token.value : 15,
      percentage: data.input_token ? data.input_token.percentage : 15,
      color: "#16BFD6",
    },
    {
      label: "Total Output Token",
      value: data.output_token ? data.output_token.value : 15,
      percentage: data.output_token ? data.output_token.percentage : 15,
      color: "#F765A3",
    },
  ];

  return (
    <div className="tcc-section">
      <h1 className="section-title">
        Input & Output Token
        <ToolTip text={'A comparison of the total consumption of Input Token and Output Token'} />
      </h1>
      <div className="rq-chart-and-legend">
        <div style={{height:'200px', width:'200px'}}>
          <Doughnut className="rq-donut" data={dataChart} options={chartOptions} />
        </div>
        <div className="rq-legend-container">
          {legendData.map((item, index) => (
            <div key={index} className="rq-legend-item">
              <div className="rq-legend-info">
                <div
                  className="rq-legend-color-box"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span style={{ marginRight: "10px" }}>{item.label}</span>
              </div>
              <div className="rq-legend-value">
                <span className="rq-value">{numberWithCommas(item.value)}</span>
                <div className="rq-percentage-bar">
                  <div
                    className="rq-percentage-fill"
                    style={{
                      width: item.percentage + "%",
                      backgroundColor: item.color,
                    }}
                  ></div>
                </div>
                <span className="rq-percentage-text">{item.percentage}%</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TCCDonutChart;
