import { useContext } from "react"
import { PromptMetricsContext } from "../contexts/PromptMetricsProvider"

const usePromptMetrics = () => {
    const {
        currentPage,
        setCurrentPage,
        selectedFilters,
        setSelectedFilters
    } = useContext(PromptMetricsContext)

    return {
        currentPage,
        setCurrentPage,
        selectedFilters,
        setSelectedFilters
    }
}

export default usePromptMetrics;