import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

export const PromptFunctionsContext = createContext();

export default function PromptFunctionsProvider() {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState({
        function_name: [],
        time_start: "",
        time_end: "",
      });
    
    return (
        <PromptFunctionsContext.Provider value={{
            currentPage,
            setCurrentPage,
            selectedFilters,
            setSelectedFilters
        }}>
            <Outlet />
        </PromptFunctionsContext.Provider>
    )
}