import ToolTip from "../../components/ToolTip"
import tooltipIcon from "../../assets/tooltipIcon.svg";
import openModalIcon from "../../assets/openModal.svg";
import redirectIcon from "../../assets/redirectIcon.svg";
import { useNavigate } from "react-router-dom";

const RQOverview = ({ overviewData, handleOpenModal, selectedMonth, selectedYear }) => {

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/fallback-management");
  };
  return (
    <div className="rq-boxes">
      <div className="rq-box">
        <div className="box-title">
          Success Rate
          <ToolTip text={"The percentage of the chatbot's success rate based on the total number of chatbot responses (excluding responses that are directed to Fallback Management)"} />
        </div>
        <p className="box-value">
          {overviewData.success_rate
            ? Math.round(overviewData.success_rate.rate * 100) / 100
            : 0
          }%</p>
        <p className="box-date">
          {
            (selectedMonth.length == 1 || selectedYear.length == 1) &&
            <>
              <span
                style={{ color: overviewData.success_rate && overviewData.success_rate.growth >= 0 ? 'green' : 'red' }}
              >
                {overviewData.success_rate
                  ? overviewData.success_rate.growth >= 0 ? `+${Math.round(overviewData.success_rate.growth * 100) / 100}` : Math.round(overviewData.success_rate.growth * 100) / 100
                  : 0
                }{"% "}
              </span>
              from last month
            </>
          }
        </p>
      </div>
      <div className="rq-box">
        <div className="box-title">
          Error/Fallback Rate
          <ToolTip text={"The percentage of the chatbot's error rate based on the total number of chatbot responses in Fallback Management"} />
          <img
            src={redirectIcon}
            alt="redirect icon"
            className="redirect-icon cursor-pointer"
            onClick={handleRedirect}
          />
        </div>
        <p className="box-value">
          {overviewData.error_rate
            ? Math.round(overviewData.error_rate.rate * 100) / 100
            : 0
          }%</p>
        <p className="box-date">
          {
            (selectedMonth.length == 1 || selectedYear.length == 1) &&
            <>
              <span
                style={{ color: overviewData.error_rate && overviewData.error_rate.growth >= 0 ? 'green' : 'red' }}
              >
                {overviewData.error_rate
                  ? overviewData.error_rate.growth >= 0 ? `+${Math.round(overviewData.error_rate.growth * 100) / 100}` : Math.round(overviewData.error_rate.growth * 100) / 100
                  : 0
                }% {""}
              </span>
              from last month
            </>
          }
        </p>
      </div>
      <div className="rq-box">
        <div className="box-title">
          Satisfaction Rate
          <ToolTip text={"The percentage of user satisfaction based on the total number of thumbs-up reactions given by users"} />
        </div>
        <p className="box-value">
          {overviewData.satisfaction_rate
            ? Math.round(overviewData.satisfaction_rate.rate * 100) / 100
            : 0
          }%</p>
        <p className="box-date">
          {
            (selectedMonth.length == 1 || selectedYear.length == 1) &&
            <>
              <span
                style={{ color: overviewData.satisfaction_rate && overviewData.satisfaction_rate.growth >= 0 ? 'green' : 'red' }}
              >
                {overviewData.satisfaction_rate
                  ? overviewData.satisfaction_rate.growth >= 0 ? `+${Math.round(overviewData.satisfaction_rate.growth * 100) / 100}` : Math.round(overviewData.satisfaction_rate.growth * 100) / 100
                  : 0}% {""}
              </span>
              from last month
            </>
          }
        </p>
      </div>
      <div className="rq-box">
        <div className="box-title">
          Avg. User Rating
          <ToolTip text={"The average percentage of user satisfaction based on the ratings given by users"} />
          <img
            src={openModalIcon}
            alt="open modal icon"
            className="redirect-icon cursor-pointer"
            onClick={() => handleOpenModal("userRating")}
          />
        </div>
        <p className="box-value">
          {overviewData.user_rating
            ? `${Math.round(overviewData.user_rating.rate * 10) / 10}/5 `
            : "0/5 "
          }
          <span className="box-sub-value">
            &#40;{overviewData.user_rating?.total_feedback} feedback&#41;
          </span>
        </p>
        <p className="box-date">
          {
            (selectedMonth.length == 1 || selectedYear.length == 1) &&
            <>
              <span
                style={{ color: overviewData.user_rating && overviewData.user_rating.growth >= 0 ? 'green' : 'red' }}
              >
                {overviewData.user_rating
                  ? overviewData.user_rating.growth >= 0 ? `+${Math.round(overviewData.user_rating.growth * 10) / 10}` : Math.round(overviewData.user_rating.growth * 10) / 10
                  : 0
                } {""}
              </span>
              from last month
            </>
          }
        </p>
      </div>
      <div className="rq-box">
        <div className="box-title">
          Avg. Response Time
          <ToolTip text={"The average percentage of time taken by chatbot to respond to users"} />
        </div>
        <p className="box-value">
          {overviewData.response_time
            ? Math.round(overviewData.response_time.rate * 100) / 100
            : 0
          }s</p>
        <p className="box-date">
          {
            (selectedMonth.length == 1 || selectedYear.length == 1) &&
            <>
              <span
                style={{ color: overviewData.response_time && overviewData.response_time.growth >= 0 ? 'green' : 'red' }}
              >
                {overviewData.response_time
                  ? overviewData.response_time.growth >= 0 ? `+${Math.round(overviewData.response_time.growth * 100) / 100}` : Math.round(overviewData.response_time.growth * 100) / 100
                  : 0
                }s
              </span>
              from last month
            </>
          }
        </p>
      </div>
    </div>
  )
}

export default RQOverview