import { useCallback, useEffect, useState } from "react";
import ToolTip from "../../components/ToolTip";
import LoadingOverview from "../../components/LoadingOverview";
import axios from "axios";
import debounce from "../../utils/Debounce";

const TopQueries = ({ selectedFilters, selectedMonth, selectedYear }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const getTopQueries = useCallback(debounce(async (filters) => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://genai-cms-api.nawatech.co/api/sma/user-engagement/top-queries', 
        filters
      )
      if (response.status === 200) {
        const result = response.data;
        setData(result.data);
      }
    } catch (err) {
      console.error('Error fetching top queries', err);
    }
    setIsLoading(false);
  }, 1000), [])

  useEffect(() => {
    getTopQueries(selectedFilters);
  }, [selectedFilters])

  return (
    <div className="ue-section w-400">
      <h3 className="section-title">
        Top Queries
        <ToolTip text={"Most frequently queried Metric, Location, Month and  Year by users"} />
      </h3>

      {
        isLoading
          ? <>
            <LoadingOverview length={2} prefixCSS={'ue'} />
            <div className="mt-8"></div>
            <LoadingOverview length={2} prefixCSS={'ue'} />
          </>
          : (
            <>
              <div className="ue-boxes">
                <div className="ue-box">
                  <p className="box-title">
                    Top Metric Queried
                  </p>
                  <p className="box-value-sm">
                    {data.top_metric}
                  </p>
                  <p className="box-date">
                    5/20
                  </p>
                </div>
                <div className="ue-box">
                  <p className="box-title">
                    Top Location
                  </p>
                  <p className="box-value-sm">
                    {data.top_location}
                  </p>
                  <p className="box-date">
                    5/20
                  </p>
                </div>
              </div>
              <div className="ue-boxes mt-8">
                <div className="ue-box">
                  <p className="box-title">
                    Top Month Queried
                  </p>
                  <p className="box-value-sm">
                    {data.top_month}
                  </p>
                  <p className="box-date">
                    3/20
                  </p>
                </div>
                <div className="ue-box">
                  <p className="box-title">
                    Top Year Queried
                  </p>
                  <p className="box-value-sm">
                    {data.top_year}
                  </p>
                  <p className="box-date">
                    4/20
                  </p>
                </div>
              </div>
            </>
          )
      }


    </div>
  )
}

export default TopQueries;