import { useState } from "react";
import cancelIcon from "../../assets/cancelIcon.svg";

const ActiveUsers = ({ isOpen, onClose }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 2;

  const sampleData = [
    {
      email: "username@mail.com",
      role: "ALL ACCESS",
      date: "25-07-2024, 14:55:39",
    },
    {
      email: "username@mail.com",
      role: "MDO 1",
      date: "25-07-2024, 14:55:39",
    },
    {
      email: "username@mail.com",
      role: "MDO 2",
      date: "25-07-2024, 14:55:39",
    }
  ]

  if (!isOpen) return null;

  const handleNextPage = () => {
    //HIT
    console.log('next');
  }

  const handlePrevPage = () => {
    //HIT
    console.log('prev');
  }
  return (
    <div className={`ue-active-user ${isOpen ? "open" : ""}`}>
      <div className="ue-active-user__content">
        <div className="ue-active-user__header">
          <h1 className="ue-active-user__title">Active Users</h1>
          <button className="ue-active-user__btn-close" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" />
          </button>
        </div>
        <div className="ue-active-user__separator" />
        <div className="ue-active-user__section">
          <div className="ue-active-user__box">
            <table className="ue-active-user__table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>
                    Role
                  </th>
                  <th>Last Activity Date</th>
                </tr>
              </thead>
              <tbody>
                {sampleData.map((value, index) => (
                  <tr key={index}>
                    <td>{value.email}</td>
                    <td>{value.role}</td>
                    <td>{value.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="ue-active-user__pagination">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
              <button onClick={handleNextPage} disabled={currentPage === Math.ceil(sampleData.length / rowsPerPage)}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveUsers;