import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "../assets/dashboardIcon.svg";
import { ReactComponent as FunctionIcon } from "../assets/functionMgmtIcon.svg";
import { ReactComponent as FallbackIcon } from "../assets/fallbackMgmtIcon.svg";
import { ReactComponent as UserRoleIcon } from "../assets/usersRoundIcon24.svg";
import { ReactComponent as FallbackReportIcon } from "../assets/fileChartColumnIcon24.svg";
import { ReactComponent as DropDownIcon } from "../assets/dropdownIcon.svg";
import { ReactComponent as BulkTestIcon } from "../assets/squareCodeIcon24.svg";
import { useEffect, useState } from "react";

import userIcon from "../assets/userIcon.jpg";
import signoutIcon from "../assets/signoutIcon.svg";
import { handleOpenDropdown } from "../redux/slices/sidePanelSlice";


const SideBar = () => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const dispatch = useDispatch();

  const isSidePanelOpen = useSelector((state) => state.sidePanel.isSidePanelOpen);
  const openDropdown = useSelector((state) => state.sidePanel.openDropdown);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathToTitle = {
      "/fallback-management": "Fallback Management",
      "/fallback-management/details": "Fallback Management",
      "/prompt-management": "Prompt Management",
      "/prompt-management/functions": "Functions",
      "/prompt-management/functions/details": "Functions",
      "/prompt-management/properties": "Properties",
      "/prompt-management/properties/general-properties-details": "Properties",
      "/prompt-management/properties/specific-properties-details": "Properties",
      "/prompt-management/metrics": "Metrics",
      "/prompt-management/metrics/details": "Metrics",
      "/analytics-dashboard": "Analytics Dashboard",
      "/analytics-dashboard/user-engagement": "User Engagement",
      "/analytics-dashboard/response-quality": "Response Quality",
      "/analytics-dashboard/token-cost-consumption": "Token/Cost Consumption",
      "/user-role-management": "User & Role Management",
      "/fallback-report": "Fallback Report",
      "/bulk-testing": "Bulk Testing",
    };

    setActiveTab(pathToTitle[location.pathname] || "Fallback Management");

    if (location.pathname === "/") {
      navigate("/fallback-management", { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleTabClick = (tabTitle, path) => {
    setActiveTab(tabTitle);
    navigate(path);
  };

  const toggleDropdown = (tabTitle) => {
    dispatch(handleOpenDropdown(openDropdown === tabTitle ? null : tabTitle));
  };

  const tabs = [
    {
      title: "Fallback Management",
      icon: <FallbackIcon className="tab-icon" />,
      path: "/fallback-management",
    },
    {
      title: "Prompt Management",
      icon: <FunctionIcon className="tab-icon" />,
      path: "/prompt-management",
      hasDropdown: true,
      dropdownTabs: [
        { title: "Functions", path: "/prompt-management/functions" },
        { title: "Properties", path: "/prompt-management/properties" },
        { title: "Metrics", path: "/prompt-management/metrics" },
      ],
    },
    {
      title: "Analytics Dashboard",
      icon: <DashboardIcon className="tab-icon fill" />,
      path: "/analytics-dashboard",
      hasDropdown: true,
      dropdownTabs: [
        { title: "User Engagement", path: "/analytics-dashboard/user-engagement" },
        { title: "Response Quality", path: "/analytics-dashboard/response-quality" },
        { title: "Token/Cost Consumption", path: "/analytics-dashboard/token-cost-consumption" },
      ],
    },
    {
      title: "User & Role Management",
      icon: <UserRoleIcon className="tab-icon" />,
      path: "/user-role-management",
    },
    {
      title: "Fallback Report",
      icon: <FallbackReportIcon className="tab-icon" />,
      path: "/fallback-report",
    },
    {
      title: "Bulk Testing",
      icon: <BulkTestIcon className="tab-icon" />,
      path: "/bulk-testing",
    },
  ];

  const renderBottomTabs = () => {
    return (
      <div className={`bottom-tabs ${isSidePanelOpen ? "" : "side-panel-closed"}`}>
        <div className="bottom-tab" onClick={() => alert("User Profile")}>
          <img src={userIcon} className="user-icon" alt="User Icon" />
          {isSidePanelOpen && <p className="tab-name">User</p>}
        </div>
        <div className="bottom-tab" onClick={() => alert("Sign Out")}>
          <img src={signoutIcon} className="tab-icon" alt="Signout Icon" />
          {isSidePanelOpen && <p className="sign-out">Sign Out</p>}
        </div>
      </div>
    );
  };

  return (
    <>{
      tabs.map((tab) => (
        <div key={tab.title} className={`tab-wrapper ${!isSidePanelOpen && 'close'}`}>
          <div
            className={`tab ${activeTab === tab.title ? "active" : ""}`}
            onClick={() =>
              tab.hasDropdown ? toggleDropdown(tab.title) : handleTabClick(tab.title, tab.path)
            }
          >
            <div className="tab-content">
              {tab.icon}
              {isSidePanelOpen && <p className="tab-name">{tab.title}</p>}
            </div>
            {isSidePanelOpen && tab.hasDropdown && (
              <DropDownIcon
                className={`dropdown-icon ${openDropdown === tab.title ? "rotate" : ""}`}
              />
            )}
          </div>
          {tab.hasDropdown && openDropdown === tab.title && (
            <div className={`${isSidePanelOpen ? 'dropdown' : 'sub-tab'}`}>
              {tab.dropdownTabs.map((subTab) => (
                <div
                  key={subTab.title}
                  className={`sub-tab-item ${activeTab === subTab.title ? "active" : ""} ${isSidePanelOpen ? 'side-panel-open' : ''}`}
                  onClick={() => handleTabClick(subTab.title, subTab.path)}
                >
                  <p className="sub-tab-name">{subTab.title}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))
    }</>
  )
}

export default SideBar