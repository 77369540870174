import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";

const FunctionFilter = ({ onApply, oldSelectedValue }) => {
  const [selectedValue, setSelectedValue] = useState(oldSelectedValue);
  const [functionOptions, setFunctionOptions] = useState([]);

  const getFunctionOptions = useCallback(() => {
    axios({
      method: "get",
      baseURL: "https://genai-cms-api.nawatech.co/api/sma",
      url: `/prompt_properties/function_list`,
    })
      .then((response) => {
        const { data } = response.data;
        if (Array.isArray(data)) {
          const options = data.map((option, index) => ({
            value: option.func_id,
            label: option.func_name,
          }));
          setFunctionOptions([{ value: "", label: "All" }, ...options]);
        }
      })
      .catch((error) => {
        console.error("Error fetching capabilities:", error);
      });
  }, []);

  const handleInputChange = (selected) => {
    console.log("selected", selected);
    setSelectedValue(selected);
    onApply(selected);
  };

  // Get function options on component mount
  useEffect(() => {
    getFunctionOptions();
  }, [getFunctionOptions]);

  return (
    <Select
      id="filter-name"
      name="function_name"
      options={functionOptions}
      value={selectedValue}
      onChange={handleInputChange}
      placeholder="All"
      className="filter-select"
      classNamePrefix="select"
    />
  );
};

export default FunctionFilter;
