import React from 'react';

const ThumbsDown = () => {
    return (
        <>
            <span>Thumbs Down </span>&#128078;
        </>
    );
};

export default ThumbsDown