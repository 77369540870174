import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import calendarIcon from "../../assets/baseCalendarIcon20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import RQLineChart from "./RQLineChart";
import RQDonutChart from "./RQDonutChart";
import RQFilterModal from "./RQFilterModal";
import RQDateModal from "./RQDateModal"
import UserRating from "./UserRating";
import UserSatisfaction from "./UserSatisfaction";
import "./ResponseQuality.css";
import FunctionTable from "./FunctionTable";
import axios from "axios";
import debounce from "../../utils/Debounce";
import RQOverview from "./RQOverview";
import LoadingOverview from "../../components/LoadingOverview";
import { useDocumentTitle } from "../../hooks/useDocumentTitle.";
import { useSelector } from "react-redux";
import TopBar from "../../components/TopBar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ResponseQuality = () => {
  useDocumentTitle("Response Quality");
  const [selectedFilters, setSelectedFilters] = useState({
    year: "",
    psm: "",
    estate: "",
    month: "",
    metrics: "",
    region: "",
  });
  const [overviewData, setOverviewData] = useState({});
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isUserRatingOpen, setUserRatingOpen] = useState(false);
  const [isUserSatisfactionOpen, setUserSatisfactionOpen] = useState(false);
  const [isLoadingOverview, setIsLoadingOverview] = useState(true);

  const [listLabelChooseDate, setListLabelChooseDate] = useState("");
  const [listLabelChooseDateCount, setListLabelChooseDateCount] = useState(0);

  const lineChartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState("");
  const isSidePanelOpen = useSelector((state) => state.sidePanel.isSidePanelOpen);

  const months = [
    { title: "January", altTitle: 'Jan', value: 1 },
    { title: "February", altTitle: 'Feb', value: 2 },
    { title: "March", altTitle: 'Mar', value: 3 },
    { title: "April", altTitle: 'Apr', value: 4 },
    { title: "May", altTitle: 'May', value: 5 },
    { title: "June", altTitle: 'Jun', value: 6 },
    { title: "July", altTitle: 'Jul', value: 7 },
    { title: "August", altTitle: 'Aug', value: 8 },
    { title: "September", altTitle: 'Sep', value: 9 },
    { title: "October", altTitle: 'Oct', value: 10 },
    { title: "November", altTitle: 'Nov', value: 11 },
    { title: "December", altTitle: 'Dec', value: 12 }
  ];

  const currentMonth = months[new Date().getMonth()];
  const currentYear = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState([currentMonth]);
  const [selectedYear, setSelectedYear] = useState([currentYear]);

  const fetchOverviewData = useCallback(debounce(async (filters) => {
    setIsLoadingOverview(true);
    try {
      const url = `https://genai-cms-api.nawatech.co/api/sma/response-quality/overview`;
      const response = await axios.post(url, filters);
      if (response.status == 200) {
        const result = response.data;
        setOverviewData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoadingOverview(false);
  }, 2000), []);

  useEffect(() => {
    fetchOverviewData(selectedFilters);
  }, [selectedFilters]);


  const getListLabelChooseDate = (selectedMonth, selectedYear) => {
    const listLabel = []
    for (let i = 0; i < selectedYear.length; i++) {
      for (let j = 0; j < selectedMonth.length; j++) {
        listLabel.push(`${selectedMonth[j].altTitle} ${selectedYear[i]}`);
      }
    }
    setListLabelChooseDate(listLabel.slice(0, 3).join(', '));
  }

  const getListLabelChooseDateCount = (selectedMonth, selectedYear) => {
    const listLabelCount = selectedMonth.length * selectedYear.length;
    if (listLabelCount > 5) {
      setListLabelChooseDateCount(listLabelCount - 4);
    }else{
      setListLabelChooseDateCount(0);
    }
  }

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      date_months: selectedMonth.map((data) => data.value).sort((a, b) => a - b),
      date_years: selectedYear.sort((a, b) => a - b)
    }));
    getListLabelChooseDate(selectedMonth, selectedYear);
    getListLabelChooseDateCount(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear])

  const handleOpenCloseDateModal = () => {
    if (isDateModalOpen) {
      setIsDateModalOpen(false);
    } else {
      setIsDateModalOpen(true);
    }
  }

  const handleOpenModal = (modal) => {
    switch (modal) {
      case "date":
        setIsDateModalOpen(true);
        break;
      case "filter":
        setIsFilterModalOpen(true);
        break;
      case "userRating":
        setUserRatingOpen(true);
        break;
      case "userSatisfaction":
        setUserSatisfactionOpen(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "date":
        setIsDateModalOpen(false);
        break;
      case "filter":
        setIsFilterModalOpen(false);
        break;
      case "userRating":
        setUserRatingOpen(false);
        break;
      case "userSatisfaction":
        setUserSatisfactionOpen(false);
        break;
      default:
        break;
    }
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters({ ...selectedFilters, ...filters });
    handleCloseModal("filter");
  };

  const handleResize = useCallback(debounce(() => {
    const oldWidth = lineChartRef.current.offsetWidth;
    if (oldWidth > 600) {
      const newWidth = oldWidth - 500;
      setChartWidth(`${newWidth}px`);
    } else {
      setChartWidth(null);
    }
  }, 500), [lineChartRef])

  useEffect(() => {
    if (lineChartRef.current) {
      window.addEventListener('load', handleResize)
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('load', handleResize)
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [lineChartRef, handleResize])

  useEffect(() => {
    handleResize();
  }, [isSidePanelOpen, handleResize])

  const prevRoutes = [
    { name: 'Analytics Dashboard', route: null }
  ]

  return (
    <>
      <TopBar prevRoutes={prevRoutes} currentRoute={'Response Quality'} />
      <div className="response-quality-container">
        <div className="rq-header-section">
          <h1 className="rq-header-title">Response Quality</h1>
          <div className="group-flex-8">
            <div className="dropdown-calendar">
              <button className="btn-date large cursor-pointer" onClick={() => handleOpenCloseDateModal()}>
                <img
                  src={calendarIcon}
                  alt="calendar icon"
                  className="date-button-icon"
                />
                {listLabelChooseDate ? listLabelChooseDate : "Choose Date"} {listLabelChooseDateCount > 0 && `+${listLabelChooseDateCount}`}
              </button>
              <RQDateModal
                isOpen={isDateModalOpen}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                setSelectedMonth={setSelectedMonth}
                setSelectedYear={setSelectedYear}
                currentMonth={currentMonth}
                currentYear={currentYear}
              />
            </div>
            <button
              className="btn-base with-icon font-base"
              onClick={() => handleOpenModal("filter")}
            >
              <img
                src={filterIcon}
                alt="filter icon"
                className="filter-button-icon"
              />
              Filter
            </button>
          </div>
        </div>

        <RQFilterModal
          isOpen={isFilterModalOpen}
          onClose={() => handleCloseModal("filter")}
          onApply={handleFilterApply}
        />
        <UserRating isOpen={isUserRatingOpen} onClose={() => handleCloseModal("userRating")} selectedFilters={selectedFilters} selectedMonth={selectedMonth} selectedYear={selectedYear} />
        <UserSatisfaction isOpen={isUserSatisfactionOpen} onClose={() => handleCloseModal("userSatisfaction")} selectedFilters={selectedFilters} selectedMonth={selectedMonth} selectedYear={selectedYear} />

        <div className="rq-section">
          <h2 className="section-title">Overview</h2>
          {
            isLoadingOverview
              ? <LoadingOverview length={5} prefixCSS={'rq'} />
              : <RQOverview
                isLoading={isLoadingOverview}
                overviewData={overviewData}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                handleOpenModal={handleOpenModal}
              />
          }
        </div>

        <div className="rq-section-container" ref={lineChartRef}>
          <RQLineChart
            chartWidth={chartWidth}
            selectedFilters={selectedFilters}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
          />
          <RQDonutChart onOpenUserSatisfaction={() => handleOpenModal("userSatisfaction")} selectedFilters={selectedFilters} selectedMonth={selectedMonth} selectedYear={selectedYear} />
        </div>

        <FunctionTable selectedFilters={selectedFilters} selectedMonth={selectedMonth} selectedYear={selectedYear} />
      </div>
    </>
  );
};

export default ResponseQuality;
