import LoadingSkeleton from "./LoadingSkeleton"

const LoadingOverview = ({ length, prefixCSS }) => {
  return (
    <div className={`${prefixCSS}-boxes`}>
      {[...Array(length)].map((value, index) => (
        <div key={index} className={`${prefixCSS}-box`}>
          <div className="box-title">
            <LoadingSkeleton height="14px" width="30%" />
          </div>
          <div className="box-value">
            <LoadingSkeleton height="18px" width="20%" />
          </div>
          <div className="box-date">
            <LoadingSkeleton height="10px" width="70%" />
          </div>
        </div>
      ))}
    </div>
  )
}

export default LoadingOverview