import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

export const FallbackManagementContext = createContext();

export default function FallbackManagementProvider() {
    const [activeTab, setActiveTab] = useState("Overview");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentOverviewPage, setCurrentOverviewPage] = useState(1);
    const [selectedOverviewFilters, setSelectedOverviewFilters] = useState({
        detectedCapability: null,
        selectedCapability: null
    });

    const [currentAnalyticsPage, setCurrentAnalyticsPage] = useState(1);
    const [selectedAnalyticsFilters, setSelectedAnalyticsFilters] = useState({
        detectedCapability: null,
        month: null,
        year: null,
        metrics: null,
        psm: null,
        region: null,
        estate: null,
    });

    return (
        <FallbackManagementContext.Provider value={{
            activeTab,
            setActiveTab,
            currentOverviewPage,
            setCurrentOverviewPage,
            selectedOverviewFilters,
            setSelectedOverviewFilters,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            currentAnalyticsPage,
            setCurrentAnalyticsPage,
            selectedAnalyticsFilters,
            setSelectedAnalyticsFilters
        }}>
            <Outlet />
        </FallbackManagementContext.Provider>
    )
}