import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";

import FallbackManagement from "./FallbackMgmt/FallbackManagement";
import FallbackDetails from "./FallbackMgmt/FallbackDetails";
import Functions from "./PromptMgmt/Functions/Functions"
import FunctionDetails from "./PromptMgmt/Functions/FunctionDetails";
import Metrics from "./PromptMgmt/Metrics/Metrics";
import MetricDetails from "./PromptMgmt/Metrics/MetricDetails";
import Properties from "./PromptMgmt/Properties/Properties";
import InDevelopment from "./InDevelopment/InDevelopment";
import GeneralPropertiesDetail from "./PromptMgmt/Properties/GeneralPropertiesDetail";
import SpecificPropertiesDetail from "./PromptMgmt/Properties/SpecificPropertiesDetail";
import ResponseQuality from "./AnalyticsDashboard/ResponseQuality/ResponseQuality";
import UserEngagement from "./AnalyticsDashboard/UserEngagement/UserEngagement";
import TokenCostConsumption from "./AnalyticsDashboard/TokenCostConsumption/TokenCostConsumption";
import NotFoundPage from "./NotFoundPage";
import MainLayout from "./components/MainLayout";
import Login from "./Login/Login";
import PrivateRoute from "./auth/PrivateRoute";
import FallbackManagementProvider from "./contexts/FallbackManagementProvider";
import PromptMetricsProvider from "./contexts/PromptMetricsProvider";
import PromptPropertiesProvider from "./contexts/PromptPropertiesProvider";
import PromptFunctionsProvider from "./contexts/PromptFunctionsProvider";


const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/login" element={<InDevelopment />} />
        <Route element={<MainLayout />} >
          <Route path="/" element={<Navigate to={'/fallback-management'} />} />
          <Route element={<FallbackManagementProvider />}>
            <Route path="/fallback-management" element={<FallbackManagement />} />
            <Route path="/fallback-management/details" element={<FallbackDetails />} />
          </Route>
          {/* <Route path="/prompt-management" element={<InDevelopment />} /> */}
          <Route element={<PromptPropertiesProvider />}>
            <Route path="/prompt-management/properties" element={<Properties />} />
            <Route path="/prompt-management/properties/general-properties-details" element={<GeneralPropertiesDetail />} />
            <Route path="/prompt-management/properties/specific-properties-details" element={<SpecificPropertiesDetail />} />
          </Route>
          <Route element={<PromptFunctionsProvider />}>
            <Route path="/prompt-management/functions" element={<Functions />} />
            <Route path="/prompt-management/functions/details" element={<FunctionDetails />} />
          </Route>
          <Route element={<PromptMetricsProvider />}>
            <Route path="/prompt-management/metrics" element={<Metrics />} />
            <Route path="/prompt-management/metrics/details" element={<MetricDetails />} />
          </Route>
          {/* <Route path="/analytics-dashboard" element={<InDevelopment />} /> */}
          {/* <Route path="/analytics-dashboard/user-engagement" element={<InDevelopment />} /> */}
          <Route path="/analytics-dashboard/user-engagement" element={<UserEngagement />} />
          <Route path="/analytics-dashboard/response-quality" element={<ResponseQuality />} />
          <Route path="/analytics-dashboard/token-cost-consumption" element={<TokenCostConsumption />} />
          <Route path="/user-role-management" element={<InDevelopment />} />
          <Route path="/fallback-report" element={<InDevelopment />} />
          <Route path="/bulk-testing" element={<InDevelopment />} />
        </Route>
        <Route element={<PrivateRoute />}>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  )
};

export default App;