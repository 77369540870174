import chevronLeftMed from "../assets/chevronLeftMed.svg";

const CustomeContainerDatePicker = (children, handleClear, onClose, onApply, showInput) => {
    return (
        <div className="date-picker-container">
            {showInput
                ? null
                : <>
                    <div className="date-picker-top">
                        <button className="calendar-back-button" onClick={onClose}>
                            <img
                                src={chevronLeftMed}
                                alt="calendar icon"
                                className="calender-back-icon" />
                        </button>
                        <p>Custome Range</p>
                    </div>
                    <hr />
                </>
            }
            {children}
            <div className="date-picker-footer">
                <button className="btn-calendar-clear" onClick={handleClear}>Clear</button>
                <button className="btn-calendar-apply" onClick={onApply}>Apply</button>
            </div>
        </div>
    )
}


export default CustomeContainerDatePicker;