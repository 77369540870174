import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";


export const PromptPropertiesContext = createContext();

export default function PromptPropertiesProvider() {
    const [activeTab, setActiveTab] = useState("General Properties");
    
    const [currentGeneralPage, setCurrentGeneralPage] = useState(1);

    const [selectedSpesificFilters, setSelectedSpesificFilters] = useState([]);
    const [currentSpesificPage, setCurrentSpesificPage] = useState(1);

    return (
        <PromptPropertiesContext.Provider value={{
            activeTab,
            setActiveTab,
            currentGeneralPage,
            setCurrentGeneralPage,
            selectedSpesificFilters,
            setSelectedSpesificFilters,
            currentSpesificPage,
            setCurrentSpesificPage
        }}>
            <Outlet />
        </PromptPropertiesContext.Provider>
    )
}