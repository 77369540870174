import { useContext } from "react"
import { PromptFunctionsContext } from "../contexts/PromptFunctionsProvider";

const usePromptFunctions = () => {
    const {
        currentPage,
        setCurrentPage,
        selectedFilters,
        setSelectedFilters
    } = useContext(PromptFunctionsContext)

    return {
        currentPage,
        setCurrentPage,
        selectedFilters,
        setSelectedFilters
    }
}

export default usePromptFunctions;