import React, { useState } from 'react';

const DateModal = ({ isOpen, selectedMonth, selectedYear, setSelectedMonth, setSelectedYear, currentMonth, currentYear }) => {
  const [selectedMonthAll, setSelectedMonthAll] = useState(false)
  const [selectedYearAll, setSelectedYearAll] = useState(false)

  const months = [
    { title: "January", altTitle: 'Jan', value: 1 },
    { title: "February", altTitle: 'Feb', value: 2 },
    { title: "March", altTitle: 'Mar', value: 3 },
    { title: "April", altTitle: 'Apr', value: 4 },
    { title: "May", altTitle: 'May', value: 5 },
    { title: "June", altTitle: 'Jun', value: 6 },
    { title: "July", altTitle: 'Jul', value: 7 },
    { title: "August", altTitle: 'Aug', value: 8 },
    { title: "September", altTitle: 'Sep', value: 9 },
    { title: "October", altTitle: 'Oct', value: 10 },
    { title: "November", altTitle: 'Nov', value: 11 },
    { title: "December", altTitle: 'Dec', value: 12 }
  ];

  const years = [];
  for (let year = 2023; year <= currentYear; year++) {
    years.push(year);
  }

  const handleSelectMonth = (data) => {
    if (selectedMonthAll) setSelectedMonthAll(false)
    if (!selectedMonth.some(obj => obj.value === data.value)) {
      setSelectedMonth([...selectedMonth, data]);
    } else {
      setSelectedMonth(selectedMonth.filter((obj) => obj.value !== data.value));
    }
  }

  const handleSelectMonthAll = (e) => {
    if (e.target.checked) {
      setSelectedMonth(months.map(month => month))
      setSelectedMonthAll(true)
    } else {
      setSelectedMonth([currentMonth])
      setSelectedMonthAll(false)
    }
  }

  const handleSelectYear = (value) => {
    if (selectedYearAll) setSelectedYearAll(false)
    if (!selectedYear.includes(value)) {
      setSelectedYear([...selectedYear, value]);
    } else {
      setSelectedYear(selectedYear.filter((item) => item !== value));
    }
  }

  const handleSelectYearAll = (e) => {
    if (e.target.checked) {
      setSelectedYear(years.map(year => year))
      setSelectedYearAll(true)
    } else {
      setSelectedYear([currentYear])
      setSelectedYearAll(false)
    }
  }

  if (!isOpen) return null;

  return (
    <div className="date-modal">
      <div className="date-modal-content large">
        <div className="group-flex-16">
          <ul>
            <li>
              <label className='form-checkbox'>
                <input type='checkbox' checked={selectedMonthAll} onChange={(e) => handleSelectMonthAll(e)} />
                <span className='checkmark'></span>
                All
              </label>
            </li>
            {months.map((month) => (
              <li key={month.value}>
                <label className='form-checkbox cursor-pointer'>
                  <input type='checkbox'
                    id={`${month.title}-checkbox`}
                    checked={selectedMonth.some(obj => obj.value === month.value) ? true : false}
                    onChange={() => handleSelectMonth(month)}
                    disabled={selectedMonth.some(obj => obj.value === month.value) && selectedMonth.length == 1} />
                  <span className='checkmark'></span>
                  {month.title}
                </label>
              </li>
            ))}
          </ul>
          <div className="separator-line"></div>
          <ul>
            <li>
              <label className='form-checkbox'>
                <input type='checkbox' checked={selectedYearAll} onChange={(e) => handleSelectYearAll(e)} />
                <span className='checkmark'></span>
                All
              </label>
            </li>
            {years.map((year) => (
              <li key={year}>
                <label className='form-checkbox'>
                  <input type='checkbox' id={`${year}-checkbox`} checked={selectedYear.includes(year)} onChange={() => handleSelectYear(year)} disabled={selectedYear.includes(year) && selectedYear.length == 1} />
                  <span className='checkmark'></span>
                  {year}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};


export default DateModal;
