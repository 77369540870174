import React from "react";
import sinarmasGrey from "../assets/sinarmasGrey.svg";
import "./InDevelopment.css";
import { useDocumentTitle } from "../hooks/useDocumentTitle.";

const InDevelopment = () => {
  useDocumentTitle("In Developement");

  return (
    <div className="development">
      <img src={sinarmasGrey} className="development-img" alt="Sinarmas Logo" />
      <h2 className="development-text">Unfortunately! This page is currently still under development</h2>
    </div>
  );
};

export default InDevelopment;
