import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { me } from "../redux/slices/authSlice";
import { useEffect } from "react";
import LoadingPage from "../LoadingPage";

const PrivateRoute = () => {

    const dispatch = useDispatch();
    const { isLoading, isUserLoggedIn } = useSelector(state => state.auth);

    useEffect(() => {
        if (isUserLoggedIn === null) {
            dispatch(me());
        }
    }, [dispatch]);

    if (isLoading) return <LoadingPage />

    return (
        isUserLoggedIn ? <Outlet /> : <Navigate to={'/login'} />
    )
}


export default PrivateRoute;