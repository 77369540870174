import CustomeContainerDatePicker from "./CustomeContainerDatePicker";
import DatePicker from "react-datepicker";
import CustomInputDatePicker from "./CustomInputDatePicker";
import CustomInputRangeDatePicker from "./CustomInputRangeDatePicker";
import { useState } from "react";
import Select from "react-select";
import chevronLeft from "../assets/chevronLeftIcon24.svg";
import chevronRight from "../assets/chevronRightIcon24.svg";
import { getMonth, getYear } from "date-fns";

const CustomDatePicker = ({ onApply, onClose, dateRef, showInput, oldStartDate, oldEndDate }) => {
  const [startDate, setStartDate] = useState(oldStartDate);
  const [endDate, setEndDate] = useState(oldEndDate);

  const CURRENT_YEAR = new Date().getFullYear();
  const CURRENT_MONTH = new Date().getMonth();
  const years = [];
  for (let year = 2023; year <= CURRENT_YEAR; year++) {
    years.push({ value: year, label: year.toString() });
  }

  const months = [
    { value: 0, label: "January" },
    { value: 1, label: "February" },
    { value: 2, label: "March" },
    { value: 3, label: "April" },
    { value: 4, label: "May" },
    { value: 5, label: "June" },
    { value: 6, label: "July" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "October" },
    { value: 10, label: "November" },
    { value: 11, label: "December" },
  ];

  const monthsv2 = [
    "January",
    "February",
    "March",
    "April",
  ]

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    onApply(null, null);
  }

  const handleApply = () => {
    onApply(startDate, endDate);
    onClose();
  }

  return (<>
    <DatePicker
      ref={dateRef}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={handleChange}
      shouldCloseOnSelect={false}
      customInput={showInput ? <CustomInputDatePicker /> : <CustomInputRangeDatePicker />}
      renderCustomHeader={({
        date,
        changeMonth,
        changeYear,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="date-picker-header">
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className="month-button cursor-pointer">
            <img
              src={chevronLeft}
              alt="calendar icon" />
          </button>
          <Select
            className="multi-select"
            classNamePrefix="select"
            options={months}
            value={months[getMonth(date)]}
            onChange={(selected) => changeMonth(selected.value)}
            placeholder={"-- Select Division --"}
          />
          <Select
            className="multi-select"
            classNamePrefix="select"
            options={years}
            value={years.find((x) => x.value === getYear(date))}
            onChange={(selected) => changeYear(selected.value)}
          />
          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className="month-button cursor-pointer">
            <img
              src={chevronRight}
              alt="calendar icon" />
          </button>
        </div>
      )}
      calendarContainer={({ children }) => CustomeContainerDatePicker(children, handleClear, onClose, handleApply, showInput)}
    />
  </>)
}

export default CustomDatePicker;