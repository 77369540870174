import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { useCallback, useEffect, useState } from 'react';
import debounce from '../../utils/Debounce';
import axios from 'axios';
import LoadingChart from '../../components/LoadingChart';
import Select from 'react-select';


ChartJS.register(LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const TCCForecastChart = ({ }) => {
  const [selectedMetric, setSelectedMetric] = useState({ value: 'total_token_consumption', label: 'Total Token Consumption' });
  const [selectedTime, setSelectedTime] = useState({ value: 'month', label: 'This Month' });
  const [dataActual, setDataActual] = useState([]);
  const [dataForecast, setDataForecast] = useState([]);
  const [labelForecast, setLabelForcast] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const listMetrics = [
    { value: 'total_token_consumption', label: 'Total Token Consumption' },
    { value: 'total_token_cost_consumption', label: 'Total Token Cost Consumption' },
  ];

  const listTime = [
    { value: 'month', label: 'This Month' },
    { value: 'year', label: 'This Year' }
  ]

  const getData = useCallback(debounce(async (metric, time) => {
    setIsLoading(true);
    try {
      const URL = "https://genai-cms-api.nawatech.co/api/sma/token-consumption/line-chart-forecast";
      const response = await axios.post(URL, {
        line_type: metric.value,
        period_type: time.value
      });
      if (response.status === 200) {
        const result = response.data;
        const listDataActual = [];
        const listDataForecast = [];
        const listLabelChart = [];
        const actualLength = result.data.actual.length;
        const forecastLength = result.data.forecast.length;
        for (let i = 0; i < actualLength; i++) {
          listDataActual.push(result.data.actual[i].total);
          if (i === actualLength - 1) {
            listDataForecast.push(result.data.actual[i].total);
          } else {
            listDataForecast.push(null);
          }
          listLabelChart.push(result.data.actual[i].date);
        }
        for (let i = 0; i < forecastLength; i++) {
          listDataActual.push(null);
          listDataForecast.push(result.data.forecast[i].total);
          listLabelChart.push(result.data.forecast[i].date);
        }
        setDataActual(listDataActual);
        setDataForecast(listDataForecast);
        setLabelForcast(listLabelChart);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  }, 500));

  useEffect(() => {
    getData(selectedMetric, selectedTime);
  }, [selectedMetric, selectedTime]);

  const handleMetricChange = (selectedOptions) => {
    setSelectedMetric(selectedOptions);
  };

  const handleTimeChange = (selectedOptions) => {
    setSelectedTime(selectedOptions)
  };


  const chartData = {
    labels: labelForecast,
    datasets: [
      {
        data: dataActual,
        borderColor: 'rgb(22, 99, 214)',
        backgroundColor: 'rgb(22, 99, 214, 0.1)',
        fill: true,
      },
      {
        data: dataForecast,
        borderColor: 'rgb(254, 141, 20)',
        borederDash: [5, 5],
        backgroundColor: 'rgb(254, 141, 20, 0.1)',
        fill: true,
      }
    ]
  }

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    }
  }

  return (
    <div className='tcc-section'>
      <div className="group-flex-8 justify-between mb-16">
        <Select
          id="metricType"
          name="metricType"
          options={listMetrics}
          value={selectedMetric}
          onChange={handleMetricChange}
          placeholder={"-- Select Metric --"}
          classNamePrefix="select"
        />
        <Select
          id="periodType"
          name="periodType"
          options={listTime}
          value={selectedTime}
          onChange={handleTimeChange}
          placeholder={"-- Select Period --"}
          classNamePrefix="select"
        />
      </div>
      <div style={{ position: 'relative' }}>
        {isLoading &&
          <LoadingChart />
        }
        <Line
          data={chartData}
          options={chartOptions}
          height={300}
        />
      </div>
    </div>
  )
}

export default TCCForecastChart;