import React, { useCallback, useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import './TokenCostConsumption.css';
import debounce from '../../utils/Debounce';
import axios from 'axios';
import LoadingComponent from '../../components/Loading';
import LoadingChart from '../../components/LoadingChart';
import Select from 'react-select';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const TCCLineChart = ({ chartWidth, selectedFilters, selectedMonth, selectedYear }) => {

  const [selectedMetric, setSelectedMetric] = useState({ value: 'total_token_consumption', label: 'Total Token Consumption' });
  const [selectedTime, setSelectedTime] = useState({ value: 'day', label: 'Daily' });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectDayDisabled, setIsSelectDayDisabled] = useState(false)


  const listMetrics = [
    { value: 'total_token_consumption', label: 'Total Token Consumption' },
    { value: 'total_token_cost_consumption', label: 'Total Token Cost Consumption' },
    { value: 'avg_token_consumption', label: 'Avg. Total Token Consumption per Chat' },
    { value: 'avg_token_cost_consumption', label: 'Avg. Total Token Cost Consumption per Chat' }
  ];

  const listTime = [
    { value: 'day', label: 'Daily' },
    { value: 'month', label: 'Monthly' },
    { value: 'year', label: 'Yearly' }
  ]

  const getData = useCallback(debounce(async (filters, metric, time) => {
    setIsLoading(true);
    try {
      const URL = "https://genai-cms-api.nawatech.co/api/sma/token-consumption/line-chart";
      const response = await axios.post(URL, {
        ...filters,
        line_type: metric.value,
        period_type: time.value
      });
      if (response.status === 200) {
        const result = response.data;
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  }, 1000), [])

  useEffect(() => {
    getData(selectedFilters, selectedMetric, selectedTime);
  }, [selectedFilters, selectedMetric, selectedTime]);

  useEffect(() => {
    if (selectedMonth.length > 1 || selectedYear.length > 1) {
      setIsSelectDayDisabled(true);
      setSelectedTime({ value: 'month', label: 'Monthly' });
    } else {
      setIsSelectDayDisabled(false);
    }
  }, [selectedMonth, selectedYear]);

  const handleMetricChange = (selectedOptions) => {
    setSelectedMetric(selectedOptions);
  };

  const handleTimeChange = (selectedOptions) => {
    setSelectedTime(selectedOptions)
  };

  const chartData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        data: data.map((item) => item.total),
        borderColor: 'white',
        backgroundColor: '#71AEEB',
        borderWidth: 2,
        maxBarThickness: 70,
        fill: true
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className='tcc-section grow'>
      <div className="group-flex-8 justify-between mb-16">
        <Select
          id="metricType"
          name="metricType"
          options={listMetrics}
          value={selectedMetric}
          onChange={handleMetricChange}
          placeholder={"-- Select Metric --"}
          classNamePrefix="select"
        />
        <Select
          id="periodType"
          name="periodType"
          options={listTime}
          value={selectedTime}
          onChange={handleTimeChange}
          placeholder={"-- Select Period --"}
          classNamePrefix="select"
          isOptionDisabled={(option) => option.value === 'day' && isSelectDayDisabled}
        />
      </div>
      <div style={{ position: 'relative', width: chartWidth ?? '100%', height: '300px' }}>
        {isLoading &&
          <LoadingChart />
        }
        <Bar
          data={chartData}
          options={chartOptions}
        />
      </div>
    </div>
  );
};

export default TCCLineChart;