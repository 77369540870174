import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Alert } from "../../components";


const EditModal = ({ isOpen, onClose, onSuccess, formData }) => {
  const [metricName, setMetricName] = useState("");
  const [unit, setUnit] = useState("");
  const [locationGranularity, setLocationGranularity] = useState("");
  const [periodGranularity, setPeriodGranularity] = useState("");
  const [dashboardType, setDashboardType] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  
  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const updateDataMetric = (data) => {
    axios({
      method: "post",
      baseURL: "https://genai-cms-api.nawatech.co/api/sma",
      url: `prompt_metrics/update`,
      params: data,
    }).then(
      (response) => {
        const {
          data: { code, message, detail },
        } = response;
        if (code !== 200) {
          showAlert({
            type: "error",
            title: code || "Error",
            message: "Error updating data",
          });
        }

        showAlert({
          type: "success",
          message: message,
        });
        onSuccess();
      },
      (error) => {
        const { message, response: { data: { detail } = {} } = {} } = error;
        showAlert({
          type: "error",
          title: message || "Error",
          message: "Error updating data",
        });
      }
    );
  };

  useEffect(() => {
    setMetricName(formData.metrics_name ?? "");
    setUnit(formData.unit ?? "");
    setLocationGranularity(formData.location_granuality ?? "");
    setPeriodGranularity(formData.period_granuality ?? "");
    setDashboardType(formData.dashboard_type ?? "");
    setDescription(formData.description ?? "");
    setId(formData.id);
  }, [formData]);

  const handleSave = () => {
    updateDataMetric({
      metrics_name: metricName,
      unit: unit,
      location_granuality: locationGranularity,
      period_granuality: periodGranularity,
      dashboard_type: dashboardType,
      description: description,
      id: formData.id,
    });
  };

  return (
    <>
      <Alert ref={alertRef} />

      <div className={`edit-modal ${isOpen ? "open" : ""}`}>
        <div className="edit-modal-content">
          <h2 className="filter-title">Edit Metrics</h2>
          <hr className="edit-separator" />
          <div className="edit-section">
            <div className="edit-item">
              <span className="edit-name">Metric Name</span>
              <input
                className="edit-input"
                value={metricName}
                onChange={(e) =>
                  setMetricName(e.target.value)
                }
                // readOnly={!!formMetric.metrics_name}
                readOnly={true}
                placeholder="Enter metric name"
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Unit</span>
              <input
                className="edit-input"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                // readOnly={!!formMetric.unit}
                readOnly={true}
                placeholder="Enter unit"
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Location Granularity</span>
              <input
                className="edit-input"
                value={locationGranularity}
                onChange={(e) =>
                  setLocationGranularity(e.target.value)
                }
                // readOnly={!!formMetric.location_granuality}
                readOnly={true}
                placeholder="Enter location granularity"
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Period Granularity</span>
              <input
                className="edit-input"
                value={periodGranularity}
                onChange={(e) =>
                  setPeriodGranularity(e.target.value)
                }
                // readOnly={!!formMetric.period_granuality}
                readOnly={true}
                placeholder="Enter period granularity"
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Dashboard Type</span>
              <input
                className="edit-input"
                value={dashboardType}
                onChange={(e) =>
                  setDashboardType(e.target.value)
                }
                readOnly={true}
                placeholder=""
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Description</span>
              <textarea
                className="edit-input edit-description"
                placeholder="Edit the description"
                value={description}
                onChange={(e) =>
                  setDescription(e.target.value)
                }
                rows={4}
              />
            </div>
          </div>
          <div className="edit-buttons">
            <button className="edit-button cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button className="edit-button submit-button" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditModal;
