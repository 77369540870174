import ToolTip from "../../components/ToolTip";
import openModalIcon from "../../assets/shareIcon.svg";
import { useCallback, useEffect, useState } from "react";
import ActiveUsers from "./ActiveUsers";
import AvgSessionLength from "./AvgSessionLength";
import ChurnRate from "./ChurnRate";
import LoadingOverview from "../../components/LoadingOverview";
import RetentionRateModal from "./RetentionRateModal";
import axios from "axios";
import debounce from "../../utils/Debounce";
import { millisecondsToMinutes } from "date-fns";

const UserOverview = ({ selectedFilters, selectedMonth, selectedYear }) => {
  const [data, setData] = useState({});

  const [isActiveUserOpen, setIsActiveUserOpen] = useState(false);
  const [isAvgSessionLengthOpen, setIsAvgSessionLengthOpen] = useState(false);
  const [isRetentionRateOpen, setIsRetentionRateOpen] = useState(false);
  const [isChurnRateOpen, setIsChurnRateOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const getMessageSession = async (params) => {
    try {
      const URL = "https://genai-cms-api.nawatech.co/api/sma/user-engagement/overview-message-session";
      const response = await axios.post(URL, params)
      return response.data;
    } catch (error) {
      console.log('Error fetching message session data', error);
    }
  }

  const getTotalInteraction = async (params) => {
    try {
      const URL = "https://genai-cms-api.nawatech.co/api/sma/user-engagement/overview-total-interaction";
      const response = await axios.post(URL, params)
      return response.data;
    } catch (error) {
      console.log('Error fetching total interaction data', error);
    }
  }

  const getUserAnalytics = async (params) => {
    try {
      const URL = "https://genai-cms-api.nawatech.co/api/sma/user-engagement/overview-user-analytics";
      const response = await axios.post(URL, params)
      return response.data;
    } catch (error) {
      console.log('Error fetching user analytics data', error);
    }
  }

  const getAllData = useCallback(debounce((filters) => {
    setIsLoading(true);
    const messageSession = getMessageSession(filters);
    const totalInteraction = getTotalInteraction(filters);
    const userAnalytics = getUserAnalytics(filters);

    Promise.all([messageSession, totalInteraction, userAnalytics])
      .then((values) => {

        const messageSessionData = values[0].data;
        const totalInteractionData = values[1].data;
        const userAnalyticsData = values[2].data;

        setData({ ...messageSessionData, total_interaction: totalInteractionData, ...userAnalyticsData });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data', error);
      });
  }, 1000), [])

  useEffect(() => {
    getAllData(selectedFilters);
  }, [selectedFilters])

  const handleActiveUserModal = (action = null) => {
    if (action === null) {
      setIsActiveUserOpen(!isActiveUserOpen);
    } else {
      setIsActiveUserOpen(action);
    }
  }

  const handleAvgSessionLengthModal = (action = null) => {
    if (action === null) {
      setIsAvgSessionLengthOpen(!isAvgSessionLengthOpen);
    } else {
      setIsAvgSessionLengthOpen(action);
    }
  }

  const handleRetentionRateModal = (action = null) => {
    if (action === null) {
      setIsRetentionRateOpen(!isRetentionRateOpen);
    } else {
      setIsRetentionRateOpen(action);
    }
  }

  const handleChurnRateModal = (action = null) => {
    if (action === null) {
      setIsChurnRateOpen(!isChurnRateOpen);
    } else {
      setIsChurnRateOpen(action);
    }
  }

  return (
    <div className="ue-section">
      <h3 className="section-title">
        User Overview
      </h3>
      {
        isLoading
          ? <LoadingOverview length={6} prefixCSS={'ue'} />
          : (<div className="ue-boxes">
            <div className="ue-box w-280">
              <div className="box-title">
                Total Interactions
                <ToolTip text={'The total number of interactions between users and chatbot based on total messages sent by users and chatbot'} />
              </div>
              <p className="box-value">
                {data.total_interaction
                  ? Math.round(data.total_interaction.total * 100) / 100
                  : 0
                }</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.total_interaction && data.total_interaction.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.total_interaction
                        ? data.total_interaction.growth >= 0 ? `+${Math.round(data.total_interaction.growth * 100) / 100}` : Math.round(data.total_interaction.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box w-280">
              <div className="box-title">
                Total Active Users
                <ToolTip text={'The total number of active users interacting with chatbot'} />
                <img src={openModalIcon}
                  alt="share icon"
                  className="share-icon"
                  onClick={() => handleActiveUserModal(true)}
                />
                <ActiveUsers isOpen={isActiveUserOpen} onClose={() => handleActiveUserModal(false)} />
              </div>
              <p className="box-value">
                {data.active_user
                  ? Math.round(data.active_user.total * 100) / 100
                  : 0
                }</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.active_user && data.active_user.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.active_user
                        ? data.active_user.growth >= 0 ? `+${Math.round(data.active_user.growth * 100) / 100}` : Math.round(data.success_rate.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box w-280">
              <div className="box-title">
                Avg. Message/Session
                <ToolTip text={'The average number of messages sent to the chatbot in one session'} />
              </div>
              <p className="box-value">
                {data.avg_doc_count
                  ? Math.round(data.avg_doc_count.avg)
                  : 0
                }</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.avg_doc_count && data.avg_doc_count.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.avg_doc_count
                        ? data.avg_doc_count.growth >= 0 ? `+${Math.round(data.avg_doc_count.growth * 100) / 100}` : Math.round(data.avg_doc_count.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box w-280">
              <div className="box-title">
                Avg. Session Length
                <ToolTip text={'The average duration of user interacting with the chatbot in one session'} />
                <img src={openModalIcon}
                  alt="share icon"
                  className="share-icon"
                  onClick={() => handleAvgSessionLengthModal(true)}
                />
                <AvgSessionLength isOpen={isAvgSessionLengthOpen} onClose={(() => handleAvgSessionLengthModal(false))} />
              </div>
              <p className="box-value">
                {millisecondsToMinutes(data.avg_session_length.avg)}<span className="box-sub-value"> min</span>
              </p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.avg_session_length && data.avg_session_length.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.avg_session_length
                        ? data.avg_session_length.growth >= 0 ? `+${Math.round(data.avg_session_length.growth * 100) / 100}` : Math.round(data.avg_session_length.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box w-280">
              <div className="box-title">
                Retention Rate
                <ToolTip text={'The rate of users who return to use the chatbot, based on having at least 2 interactions within 1 month of their initial chat/interaction'} />
                <img src={openModalIcon}
                  alt="share icon"
                  className="share-icon"
                  onClick={() => handleRetentionRateModal(true)}
                />
                <RetentionRateModal isOpen={isRetentionRateOpen} onClose={() => handleRetentionRateModal(false)} />
              </div>
              <p className="box-value">
                {data.retention_rate
                  ? Math.round(data.retention_rate.rate * 100) / 100
                  : 0
                }%</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.retention_rate && data.retention_rate.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.retention_rate
                        ? data.retention_rate.growth >= 0 ? `+${Math.round(data.retention_rate.growth * 100) / 100}` : Math.round(data.retention_rate.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
            <div className="ue-box w-280">
              <div className="box-title">
                Churn Rate
                <ToolTip text={'The percentage of users who have not engaged with the chatbot for 1 month or longer'} />
                <img src={openModalIcon}
                  alt="share icon"
                  className="share-icon"
                  onClick={() => handleChurnRateModal(true)}
                />
                <ChurnRate isOpen={isChurnRateOpen} onClose={() => handleChurnRateModal(false)} />
              </div>
              <p className="box-value">
                {data.churn_rate
                  ? Math.round(data.churn_rate.rate * 100) / 100
                  : 0
                }%</p>
              <p className="box-date">
                {
                  (selectedMonth.length === 1 && selectedYear.length === 1) &&
                  <>
                    <span
                      style={{ color: data.churn_rate && data.churn_rate.growth >= 0 ? 'green' : 'red' }}
                    >
                      {data.churn_rate
                        ? data.churn_rate.growth >= 0 ? `+${Math.round(data.churn_rate.growth * 100) / 100}` : Math.round(data.churn_rate.growth * 100) / 100
                        : 0
                      }{"% "}
                    </span>
                    from last month
                  </>
                }
              </p>
            </div>
          </div>)
      }
    </div>
  );
}

export default UserOverview;