import React, { useEffect, useState } from "react";
import Select from "react-select";
import cancelIcon from "../../assets/cancelIcon.svg";
import axios from "axios";

const RQFilterModal = ({ isOpen, onClose, onApply }) => {
  const [filters, setFilters] = useState({
    psm: { value: "", label: "ALL" },
    region: { value: "", label: "ALL" },
    estate: { value: "", label: "ALL" },
    division: { value: "", label: "ALL" },
    complex: { value: "", label: "ALL" },
    block: { value: "", label: "ALL" },
  });
  const [listPsm, setListPsm] = useState([{ value: "", label: "ALL" }]);
  const [listRegion, setListRegion] = useState([{ value: "", label: "ALL" }]);
  const [listEstate, setListEstate] = useState([{ value: "", label: "ALL" }]);
  const [listDivision, setListDivision] = useState([{ value: "", label: "ALL" },]);
  const [listComplex, setListComplex] = useState([{ value: "", label: "ALL" },]);


  const options = [
    { value: "", label: "ALL" },
    { value: "option_2", label: "Option 2" },
    { value: "option_3", label: "Option 3" },
  ];

  const getListPsm = async () => {
    try {
      const response = await axios.get("https://genai-cms-api.nawatech.co/api/sma/filter-location/psm");
      if (response.status === 200) {
        const defaultOption = [
          { value: "", label: "ALL" },
        ]
        response.data.data.map((item) => {
          return defaultOption.push({ value: item, label: item })
        })
        setListPsm(defaultOption)
      }
    } catch (error) {
      console.log("Failed to fetch data", error)
    }
  }

  const getListRegion = async (params) => {
    try {
      const response = await axios.get("https://genai-cms-api.nawatech.co/api/sma/filter-location/region", {
        params: params
      });
      if (response.status === 200) {
        const defaultOption = [
          { value: "", label: "ALL" },
        ]
        response.data.data.map((item) => {
          return defaultOption.push({ value: item, label: item })
        })
        setListRegion(defaultOption)
      }
    } catch (error) {
      console.log("Failed to fetch data", error)
    }
  }

  const getListEstate = async (params) => {
    try {
      const response = await axios.get("https://genai-cms-api.nawatech.co/api/sma/filter-location/estate", {
        params: params
      });
      if (response.status === 200) {
        const defaultOption = [
          { value: "", label: "ALL" },
        ]
        response.data.data.map((item) => {
          return defaultOption.push({ value: item, label: item })
        })
        setListEstate(defaultOption)
      }
    } catch (error) {
      console.log("Failed to fetch data", error)
    }
  }

  const getListDivision = async (params) => {
    try {
      const response = await axios.get("https://genai-cms-api.nawatech.co/api/sma/filter-location/division", {
        params: params
      });
      if (response.status === 200) {
        const defaultOption = [
          { value: "", label: "ALL" },
        ]
        response.data.data.map((item) => {
          return defaultOption.push({ value: item, label: item })
        })
        setListDivision(defaultOption)
      }
    } catch (error) {
      console.log("Failed to fetch data", error)
    }
  }

  const getListComplex = async (params) => {
    try {
      const response = await axios.get("https://genai-cms-api.nawatech.co/api/sma/filter-location/complex", {
        params: params
      });
      if (response.status === 200) {
        const defaultOption = [
          { value: "", label: "ALL" },
        ]
        response.data.data.map((item) => {
          return defaultOption.push({ value: item, label: item })
        })
        setListComplex(defaultOption)
      }
    } catch (error) {
      console.log("Failed to fetch data", error)
    }
  }

  const getListBlock = async (params) => {
    try {
      const response = await axios.get("https://genai-cms-api.nawatech.co/api/sma/filter-location/block", {
        params: params
      });
      if (response.status === 200) {
        const data = response.data.data.map((item) => {
          return {
            value: item,
            label: item
          }
        })
        // setListEstate(data)
      }
    } catch (error) {
      console.log("Failed to fetch data", error)
    }
  }

  useEffect(() => {
    getListPsm();
  }, [])

  const handleInputChange = (selectedOptions, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key.name]: selectedOptions
    }));
    handleSelectedFilter(key.name, selectedOptions.value)
  };

  const handleSelectedFilter = async (key, selectedOptions) => {
    if (key === "psm") {
      getListRegion({
        psm: selectedOptions
      })
      setFilters((prevFilters) => ({
        ...prevFilters,
        region: { value: "", label: "ALL" },
        estate: { value: "", label: "ALL" },
        division: { value: "", label: "ALL" },
        complex: { value: "", label: "ALL" },
        block: { value: "", label: "ALL" }
      }));
      setListEstate([]);
      setListDivision([]);
    } else if (key === "region") {
      getListEstate({
        psm: filters.psm.value,
        region: selectedOptions
      })
      setFilters((prevFilters) => ({
        ...prevFilters,
        estate: { value: "", label: "ALL" },
        division: { value: "", label: "ALL" },
        complex: { value: "", label: "ALL" },
        block: { value: "", label: "ALL" }
      }));
    } else if (key === "estate") {
      getListDivision({
        psm: filters.psm.value,
        region: filters.region.value,
        estate: selectedOptions,
      })
      setFilters((prevFilters) => ({
        ...prevFilters,
        division: { value: "", label: "ALL" },
        complex: { value: "", label: "ALL" },
        block: { value: "", label: "ALL" }
      }));
    } else if (key === "division") {
      getListComplex({
        psm: filters.psm.value,
        region: selectedOptions,
        estate: filters.estate.value,
        division: selectedOptions
      })
      setFilters((prevFilters) => ({
        ...prevFilters,
        complex: { value: "", label: "ALL" },
        block: { value: "", label: "ALL" }
      }));
    }
  }

  const handleClear = () => {
    const clearedFitlers = {
      psm: "",
      region: "",
      estate: "",
      division: "",
      complex: "",
      block: "",
    }
    setFilters({
      psm: { value: "", label: "ALL" },
      region: { value: "", label: "ALL" },
      estate: { value: "", label: "ALL" },
      division: { value: "", label: "ALL" },
      complex: { value: "", label: "ALL" },
      block: { value: "", label: "ALL" },
    });
    onApply(clearedFitlers);
    onClose();
  };

  const handleSubmit = () => {
    const appliedFilters = {
      ...filters,
      dashboard_type: filters.dashboard_type,
      psm: filters.psm?.value,
      region: filters.region?.value,
      estate: filters.estate?.value,
      division: filters.division?.value,
      complex: filters.complex?.value,
      block: filters.block?.value,
    };
    onApply(appliedFilters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay">
      <div className="rq filter-modal">
        <div className="filter-header">
          <h2 className="filter-title">Filter</h2>
          <button className="filter-close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="filter-separator" />
        <div className="filter-form-group">
          <label htmlFor="filter-name">Dashboard Type</label>
          <Select
            id="role"
            name="role"
            options={options}
            value={filters.dashboard_type}
            onChange={handleInputChange}
            placeholder="-- Select Dashboard Type --"
            className="multi-select"
            classNamePrefix="select"
          />
        </div>
        <div className="filter-form-row">
          <div className="filter-form-group">
            <label htmlFor="filter-name">PSM</label>
            <Select
              id="psm"
              name="psm"
              options={listPsm}
              value={filters.psm}
              onChange={handleInputChange}
              placeholder="-- Select PSM --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
            />
          </div>
          <div className="filter-form-group">
            <label htmlFor="filter-name">Region</label>
            <Select
              id="region"
              name="region"
              options={listRegion}
              value={filters.region}
              onChange={handleInputChange}
              placeholder={"-- Select Region --"}
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              isDisabled={filters.psm.value === ""}
            />
          </div>
        </div>
        <div className="filter-form-row">
          <div className="filter-form-group">
            <label htmlFor="filter-name">Estate</label>
            <Select
              id="estate"
              name="estate"
              options={listEstate}
              value={filters.estate}
              onChange={handleInputChange}
              placeholder={"-- Select Estate --"}
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              isDisabled={filters.region.value === ""}
            />
          </div>
          <div className="filter-form-group">
            <label htmlFor="filter-name">Division</label>
            <Select
              id="division"
              name="division"
              options={listDivision}
              value={filters.division}
              onChange={handleInputChange}
              placeholder={"-- Select Division --"}
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              isDisabled={filters.estate.value === ""}
            />
          </div>
        </div>
        <div className="filter-form-row">
          <div className="filter-form-group">
            <label htmlFor="filter-name">Complex</label>
            <Select
              id="complex"
              name="complex"
              options={listComplex}
              value={filters.complex}
              onChange={handleInputChange}
              placeholder="-- Select Complex --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              isDisabled={filters.division.value === ""}
            />
          </div>
          <div className="filter-form-group">
            <label htmlFor="filter-name">Block</label>
            <Select
              id="block"
              name="block"
              options={options}
              value={filters.block}
              onChange={handleInputChange}
              placeholder="-- Select Block --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              isDisabled={true}
            />
          </div>
        </div>
        <div className="filter-form-actions">
          <button
            type="button"
            className="clear-button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button type="button"
            className="apply-button"
            onClick={handleSubmit}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default RQFilterModal;
