import LoadingComponent from "./Loading"

const LoadingChart = () => {

    return (
        <div className="loading-chart">
            <LoadingComponent message={'Preparing Chart...'} />
        </div>
    )
}

export default LoadingChart