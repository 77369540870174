import { Outlet } from "react-router-dom";
import { handleSwitchSidePanel } from "../redux/slices/sidePanelSlice";
import { useDispatch, useSelector } from "react-redux";

import hamburgerMenu from "../assets/arrowLeftLineIcon24.svg";
import sinarmasLogo from "../assets/sinarmasLogo.svg";
import SideBar from "./SideBar";

const MainLayout = () => {
  const isSidePanelOpen = useSelector((state) => state.sidePanel.isSidePanelOpen);
  const dispatch = useDispatch();
  const toggleSidePanel = () => {
    dispatch(handleSwitchSidePanel());
  }
  return (
    <div className="main-container">
      <div className={`side-panel ${isSidePanelOpen ? "open" : "closed"}`}>
        <div className="group-flex-8 justify-between">
          {isSidePanelOpen && <img
            src={isSidePanelOpen ? sinarmasLogo : hamburgerMenu}
            className="logo"
            alt="sinarmas"
          />}

          <img
            src={hamburgerMenu}
            className={!isSidePanelOpen ? "rotate" : ""}
            onClick={toggleSidePanel}
            alt="sinarmas"
          />
        </div>

        {!isSidePanelOpen && <div className="separator"></div>}
        <div className={`tabs ${isSidePanelOpen ? "" : "side-panel-closed"}`}>
          <SideBar />
        </div>
        {/* {renderBottomTabs()} */}
      </div>
      <div className={`content-area ${isSidePanelOpen ? "shifted" : ""}`}>
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;