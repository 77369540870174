import { useContext, useState } from "react"
import { FallbackManagementContext } from "../contexts/FallbackManagementProvider"

export default function useFallbackManagement() {

    const {
        activeTab,
        setActiveTab,
        currentOverviewPage,
        setCurrentOverviewPage,
        selectedOverviewFilters,
        setSelectedOverviewFilters,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        currentAnalyticsPage,
        setCurrentAnalyticsPage,
        selectedAnalyticsFilters,
        setSelectedAnalyticsFilters
    } = useContext(FallbackManagementContext)


    return {
        activeTab,
        setActiveTab,
        currentOverviewPage,
        setCurrentOverviewPage,
        selectedOverviewFilters,
        setSelectedOverviewFilters,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        currentAnalyticsPage,
        setCurrentAnalyticsPage,
        selectedAnalyticsFilters,
        setSelectedAnalyticsFilters
    }
}