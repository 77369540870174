import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

export const PromptMetricsContext = createContext();

export default function PromptMetricsProvider() {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState({});
    
    return (
        <PromptMetricsContext.Provider value={{
            currentPage,
            setCurrentPage,
            selectedFilters,
            setSelectedFilters
        }}>
            <Outlet />
        </PromptMetricsContext.Provider>
    )
}