import React, { useState, useEffect } from 'react';
import cancelIcon from "../assets/cancelIcon.svg";
import axios from 'axios';
import Select from "react-select";

const FilterModal = ({ isOpen, onClose, onApply, oldFilters }) => {
  const [filters, setFilters] = useState(oldFilters);

  const [capabilities, setCapabilities] = useState([]);

  const getCapabilities = async () => {
    try {
      const URL = "https://genai-cms-api.nawatech.co/api/sma/fallback/get-master-capability";
      const response = await axios.get(URL);
      if (response.status === 200) {
        const result = response.data;
        const formatedResult = result.data.map((data) => ({ value: data, label: data }));
        setCapabilities(formatedResult);
      }
    } catch (error) {
      console.error('Error fetching capabilities:', error);
    }
  }

  useEffect(() => {
    getCapabilities();
  }, []);

  const handleDetectedCapabilityChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      detectedCapability: selectedOption,
    }));
  }

  const handleSelectedCapabilityChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedCapability: selectedOption,
    }));
  }

  const handleClear = () => {
    const clearedFilters = {
      detectedCapability: null,
      selectedCapability: null
    };
    setFilters(clearedFilters);
    onApply(clearedFilters);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onApply(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay">
      <div className="filter-modal">
        <div className="filter-header">
          <h2 className="filter-title">Filter</h2>
          <button className="filter-close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="filter-separator" />
        <form className="filter-form" onSubmit={handleSubmit}>
          <div className="filter-form-group">
            <label htmlFor="detected-capability">Detected Capability</label>
            <Select
              id="detectedCapability"
              name="detectedCapability"
              options={capabilities}
              value={filters.detectedCapability}
              onChange={handleDetectedCapabilityChange}
              placeholder="-- Select Detected Capability --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={true}
            />
          </div>
          <div className="filter-form-group">
            <label htmlFor="selected-capability">Selected Capability</label>
            <Select
              id="selectedCapability"
              name="selectedCapability"
              options={capabilities}
              value={filters.selectedCapability}
              onChange={handleSelectedCapabilityChange}
              placeholder="-- Select Selected Capability --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={true}
            />
          </div>
          <div className="filter-form-actions">
            <button type="button" className="clear-button" onClick={handleClear}>
              Clear
            </button>
            <button type="submit" className="apply-button">
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterModal;
