import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Tooltip,
  BarController,
} from 'chart.js';
import { useEffect, useState } from 'react';

import { Chart } from 'react-chartjs-2';
import ToolTip from '../../components/ToolTip';

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, PointElement, Tooltip);

const TotalMetricsChart = ({selectedFilters, selectedMonth, selectedYear}) => {
  const [selectedMetric, setSelectedMetric] = useState('mvd');
  const [listColors, setListColors] = useState([]);

  const handleMetricChange = (e) => setSelectedMetric(e.target.value);

  const sampleData = {
    mvd: [32, 27, 14, 24, 35, 30].sort((a,b) => b-a),
    downstream: [32, 27, 14, 24, 35, 30, 20].sort((a,b) => b-a),
    millEstate: [32, 27, 14, 24, 35, 30, 20, 15].sort((a,b) => b-a),
    icon360: [32, 27, 14, 24, 35, 30, 20, 15, 10, 5].sort((a,b) => b-a),

  };
  const sampleLabels = {
    mvd: [
      'FFB Production',
      'eFect Field Losses',
      'IPD Effectiveness',
      'Harvest Quality',
      'Harvester Output',
      'Harvester Coverage',
    ],
    downstream: [
      'FFB Production',
      'eFect Field Losses',
      'IPD Effectiveness',
      'Harvest Quality',
      'Harvester Output',
      'Harvester Coverage',
      'Collecter Output',
    ],
    millEstate: [
      'FFB Production',
      'eFect Field Losses',
      'IPD Effectiveness',
      'Harvest Quality',
      'Harvester Output',
      'Harvester Coverage',
      'Collecter Output',
      'Collecter Coverage',
    ],
    icon360: [
      'FFB Production',
      'eFect Field Losses',
      'IPD Effectiveness',
      'Harvest Quality',
      'Harvester Output',
      'Harvester Coverage',
      'Collecter Coverage',
      'Harvesting Round',
      'Restan',
    ],
  };

  const generateColor = (ratio) => {
    let r = 209; // Mengurangi nilai merah secara bertahap
    let g = 90;       // Meningkatkan nilai hijau secara bertahap
    let b = 90;
    if (ratio < 0.5) {
      // Dari merah ke kuning
      r = 209;
      g = Math.round(2 * ratio * 90 + 90); // Mengatur hijau dari 0 ke 255
    } else {
      // Dari kuning ke hijau
      r = Math.round(209 - (119 * (ratio - 0.5) * 2)); // Mengurangi merah dari 255 ke 0
      g = 209;
    }
    return `rgb(${r}, ${g}, ${b})`;
  }

  const generateListColors = (dataLength) => {
    const colors = [];
    for (let i = 0; i < dataLength; i++) {
      const ratio = i / (dataLength - 1);
      colors.push(generateColor(ratio));
    }
    setListColors(colors);
  }

  useEffect(() => {
    const sampleDataLength = sampleData[selectedMetric].length;
    generateListColors(sampleDataLength);
  }, [selectedMetric]);

  const data = {
    labels: sampleLabels[selectedMetric],
    datasets: [
      {
        type: 'bar',
        backgroundColor: listColors,
        data: sampleData[selectedMetric],
        borderColor: 'white',
        borderWidth: 2, 
        maxBarThickness: 70
      },
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
  };

  return (
    <div className='ue-section'>
      <div className='ue-filter-container'>
        <h3 className="section-title">
          Total Metrics by Query
          <ToolTip text={"Most frequently queried Metric by users, sorted from highest to lowest"} />
        </h3>
        <select onChange={handleMetricChange}
          value={selectedMetric}
          className='ue-select-form'>
          <option value="mvd">MVD</option>
          <option value="downstream">MVD Investor - Downstream</option>
          <option value="millEstate">MVD Investor - Mill/Estate</option>
          <option value="icon360">ICON360</option>
        </select>
      </div>
      <div>
        <Chart type='bar' data={data} options={chartOptions} style={{ height: '300px' }} />
      </div>
    </div>
  )
}

export default TotalMetricsChart;