import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    isActiveTabBar: "General Properties"
}

const promptPropertiesSlice = createSlice({
    name: "promptProperties",
    initialState,
    reducers : {
        handleSwitchSide: (state, action) => {
            state.isActiveTabBar = action.payload
        }
    }
})

export const { handleSwitchSide } = promptPropertiesSlice.actions
export default promptPropertiesSlice.reducer