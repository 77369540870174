import React, { useCallback, useEffect, useState } from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import dropdownIcon from "../../assets/dropdownIcon.svg";
import backArrow from "../../assets/backArrow.svg";
import { ReactComponent as DetailsIcon } from "../../assets/detailsEye.svg";
import kebabMenu from "../../assets/kebabMenu.svg";
import "./ResponseQuality.css";
import axios from "axios";
import ThumbsUp from "../../components/ThumbsUp";
import ThumbsDown from "../../components/ThumbsDown";
import OutsideHandler from "../../components/OutsideHandler";
import LoadingComponent from "../../components/Loading";
import debounce from "../../utils/Debounce";

const UserSatisfaction = ({ isOpen, onClose, selectedFilters, selectedMonth, selectedYear }) => {
  const [userSatisfactionData, setUserSatisfactionData] = useState([]);
  const [userSatisfactionDataDetail, setUserSatisfactionDataDetail] = useState([]);
  const [userSatisfactionDataDetailPaginated, setUserSatisfactionDataDetailPaginated] = useState([]);
  const [userSatisfactionDataPaginated, setUserSatisfactionDataPaginated] = useState([]);
  const [isLoadingUserSatisfaction, setIsLoadingUserSatisfaction] = useState(true);
  const [isLoadingUserSatisfactionDetail, setIsLoadingUserSatisfactionDetail] = useState(false);

  const getUserSatisfactionData = useCallback(debounce(async (filters) => {
    setIsLoadingUserSatisfaction(true);
    try {
      const response = await axios.post("https://genai-cms-api.nawatech.co/api/sma/response-quality/total-user-satisfaction-detail",
        filters
      )
      if (response.status === 200) {
        setUserSatisfactionData(response.data.data);
      }
    } catch (error) {
      console.error("Error getting user satisfaction data:", error);
    }
    setIsLoadingUserSatisfaction(false);
  }, 1000), []);

  useEffect(() => {
    getUserSatisfactionData(selectedFilters);
  }, [selectedFilters])

  const getUserSatisfactionDataDetail = async (room_id) => {
    setIsLoadingUserSatisfactionDetail(true);
    try {
      const response = await axios.post(`https://genai-cms-api.nawatech.co/api/sma/response-quality/get-conv-history?room_id=${room_id}`,
        {
        }
      )
      if (response.status === 200) {
        setUserSatisfactionDataDetail(response.data.data);
      }
    } catch (error) {
      console.error("Error getting user satisfaction data detail:", error);
    }
    setIsLoadingUserSatisfactionDetail(false);
  }

  const [userSatisfactionCurrentPage, setUserSatisfactionCurrentPage] = useState(1);
  const [userSatisfactionDetailCurrentPage, setUserSatisfactionDetailCurrentPage] = useState(1);
  const [activeKebabIndex, setActiveKebabIndex] = useState(null);
  const [viewingDetails, setViewingDetails] = useState(false);

  const rowsPerPage = 5;

  const getLastIndex = (type) => {
    if (type === 'detail') {
      return userSatisfactionDetailCurrentPage * rowsPerPage;
    } else {
      return userSatisfactionCurrentPage * rowsPerPage;
    }
  }

  const getFirstIndex = (type) => {
    if (type === 'detail') {
      return getLastIndex('detail') - rowsPerPage;
    } else {
      return getLastIndex() - rowsPerPage;
    }
  }
  useEffect(() => {
    const paginatedData = userSatisfactionData.slice(
      getFirstIndex(),
      getLastIndex()
    );
    setUserSatisfactionDataPaginated(paginatedData);
  }, [userSatisfactionData, userSatisfactionCurrentPage])

  useEffect(() => {
    const paginatedData = userSatisfactionDataDetail.slice(
      getFirstIndex('detail'),
      getLastIndex('detail')
    );
    setUserSatisfactionDataDetailPaginated(paginatedData);
  }, [userSatisfactionDataDetail, userSatisfactionDetailCurrentPage])

  const userSatisfactionPaginator = (direction) => {
    if (
      direction === "next" &&
      userSatisfactionCurrentPage < Math.ceil(userSatisfactionData.length / rowsPerPage)
    ) {
      setUserSatisfactionCurrentPage(userSatisfactionCurrentPage + 1);
    } else if (direction === "prev" && userSatisfactionCurrentPage > 1) {
      setUserSatisfactionCurrentPage(userSatisfactionCurrentPage - 1);
    }
  }

  const userSatisfactionDetailPaginator = (direction) => {
    if (
      direction === "next" &&
      userSatisfactionDetailCurrentPage < Math.ceil(userSatisfactionDataDetail.length / rowsPerPage)
    ) {
      setUserSatisfactionDetailCurrentPage(userSatisfactionDetailCurrentPage + 1);
    } else if (direction === "prev" && userSatisfactionDetailCurrentPage > 1) {
      setUserSatisfactionDetailCurrentPage(userSatisfactionDetailCurrentPage - 1);
    }
  };

  const toggleKebabMenu = (index) => {
    setActiveKebabIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleViewDetails = (feedback) => {
    getUserSatisfactionDataDetail(feedback.conv_id);
    setUserSatisfactionDetailCurrentPage(1);
    setViewingDetails(true);
  };

  const handleCloseViewDetails = () => {
    setActiveKebabIndex(null);
  }

  const handleBackToList = () => {
    setViewingDetails(false);
  };

  if (!isOpen) return null;

  return (
    <div className={`rq-user-satisfaction ${isOpen ? "open" : ""}`}>
      <div className="rq-user-satisfaction-content">
        <div className="rq-user-satisfaction-header">
          {viewingDetails ? (
            <button className="rq-user-satisfaction-close-button" onClick={handleBackToList}>
              <img src={backArrow} alt="back arrow" />
            </button>
          ) : (
            <></>
          )}
          <h1 className="rq-user-satisfaction-title">User Satisfaction</h1>
          <button className="rq-user-satisfaction-close-button" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" />
          </button>
        </div>
        <div className="rq-user-satisfaction-separator" />

        <div className="rq-user-satisfaction-section">
          {viewingDetails ? (
            <div className="rq-user-satisfaction-details">
              <h2>Conversation History</h2>
              <div className="rq-user-satisfaction-box">
                <table className="rq-user-satisfaction-table">
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Bot Answer</th>
                      <th>Related Function</th>
                      <th>User Satisfaction</th>
                      <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingUserSatisfactionDetail
                      ? <tr><td colSpan="5"><LoadingComponent message={"Loading data..."} /></td></tr>
                      : userSatisfactionDataDetailPaginated.map((data, index) => (
                        <tr key={index}>
                          <td>{data.question}</td>
                          <td>{data.answer}</td>
                          <td>{data.function}</td>
                          <td>{data.user_satisfaction
                            ? data.user_satisfaction == 'up' ? <ThumbsUp /> : <ThumbsDown />
                            : "-"}
                          </td>
                          <td>{data.timestamp}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                <div className="rq-user-satisfaction-pagination">
                  <button
                    onClick={() => userSatisfactionDetailPaginator("prev")}
                    disabled={userSatisfactionDetailCurrentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => userSatisfactionDetailPaginator("next")}
                    disabled={
                      userSatisfactionDetailCurrentPage === Math.ceil(userSatisfactionDataDetail.length / rowsPerPage)
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="rq-user-satisfaction-box">
              <table className="rq-user-satisfaction-table">
                <thead>
                  <tr>
                    <th>User Satisfaction</th>
                    <th>Bot Answer</th>
                    <th>Related Function</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoadingUserSatisfaction
                      ? <tr><td colSpan="4"><LoadingComponent message={"Loading data..."} /></td></tr>
                      : userSatisfactionDataPaginated.map((data, index) => (
                        <tr key={index}>
                          <td>{data.thumbs
                            ? data.thumbs == 'up' ? <ThumbsUp /> : <ThumbsDown />
                            : "-"}
                          </td>
                          <td>{data.bot_answer}</td>
                          <td>{data.related_function}</td>
                          <td>
                            <div className="kebab-menu-container">
                              <img
                                src={kebabMenu}
                                className="cursor-pointer"
                                alt="kebab menu"
                                onClick={() => toggleKebabMenu(index)}
                              />
                              {activeKebabIndex === index && (
                                <OutsideHandler handleClose={handleCloseViewDetails}>
                                  <div className="rq-kebab-menu">
                                    <div
                                      className="rq-kebab-menu-option"
                                      onClick={() => handleViewDetails(data)}
                                    >
                                      <DetailsIcon className="rq-kebab-menu-icon" />
                                      View Details
                                    </div>
                                  </div>
                                </OutsideHandler>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))

                  }
                </tbody>
              </table>
              <div className="rq-user-satisfaction-pagination">
                <button
                  onClick={() => userSatisfactionPaginator("prev")}
                  disabled={userSatisfactionCurrentPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => userSatisfactionPaginator("next")}
                  disabled={
                    userSatisfactionCurrentPage === Math.ceil(userSatisfactionData.length / rowsPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSatisfaction;
