import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from './slices/authSlice';
import promptPropertiesReducer from './slices/promptPropertiesSlice';
import sidePanelReducer from './slices/sidePanelSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    promptProperties: promptPropertiesReducer,
    sidePanel: sidePanelReducer
});

const store =  configureStore({
    reducer: rootReducer
})

export default store;